.legend_store{
	padding: 20px 30px;
	img{
		max-height: 28px;
		width: auto;
	}
}

.choice{
	.col-md-4{
		display: flex;
		align-items: center;
		justify-content: center;
		a{
			padding: 100px 50px;
			background-color: #F0F0F0;
			text-align: center;
			width: 100%;
			height: 100%;
			p{
				text-transform: uppercase;
				font-size: 22px;
				margin: 20px 0 0 0;
				font-family: 'ManifoldCF-Bold';
			}
			img{
				transition: $transition;
			}
			&:hover{
				margin-top: -15px;
				img, p{
					opacity: 0.5;
				}
			}
		}
	}
}

.comp-cen{
	margin-top: 75px;
	@media (min-width: 768px) {
		height: 90vh;
		#mappa{
			height: 100%;
		}
	}
	#contenitore_mappa {
		width: 100%;
		height: 500px;
		@media (min-width: 768px){
			height: 100%;
		}
		.infobox{
			min-width: 250px;
			.getdir{
				a{
					background-color: $green-c;
					display: inline-block;
				}
			}
		}
	}
	#dealer-List{
		height: 500px;
		overflow-y: auto;
		@media (min-width: 768px){
			height: 90vh;
		}
	}
	.col-result{
		background-color: #fff;
		overflow-y: auto;
	}
	.ricercadeal{
		background-color: $bkg-gray-m;
		padding: 25px 30px;
		input{
			background: url("../img/cercamap.svg") 95% center no-repeat #fff;
			color: #666;
			border: 1px solid #ccc;
			border-radius: 0;
			padding: 20px;
			width: 100%;
		}
	}
	.dealer-item{
		padding: 20px 0;
		border-top: 1px solid #ccc;
		transition: $transition;
		&:hover{
			cursor: pointer;
			background-color: #f0f0f0;
		}
		.pinap{
			height: 35px;
		}
		.info-per-box{
			display: none;
		}
	}
}

