$acidgreen: #C3D52E;
@mixin respond-to($media) {
	@if $media == iphoneL {
		@media only screen and (min-width: 414px) { @content; }
	}
	@else if $media == ipadV {
		@media only screen and (min-width: 768px) { @content; }
	}
	@else if $media == ipadH {
		@media only screen and (min-width: 992px) { @content; }
	}
	@else if $media == notebook {
		@media only screen and (min-width: 1200px) { @content; }
	}
	@else if $media == wide {
		@media only screen and (min-width: 1400px) { @content; }
	}
	@else if $media == full {
		@media only screen and (min-width: 1600px) { @content; }
	}
}


.bann_stsusa{
	.txt{
		@media (min-width: 768px){
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;
		}
		@media (min-width: 1280px){
			bottom: 40px;
			h2{
				font-family: 'ManifoldCF-ExtraBoldOblique';
				font-size: 40px;
			}
		}
	}
}
.available{
	p{
		margin: 0;
		font-family: 'ManifoldCF-ExtraBoldOblique';
		line-height: 100%;
		@media (min-width: 1280px){
			font-size: 28px;
		}
	}
}
.ban-sts-video{
	background: #F7F7F7;
	text-align: center;
	.treloghi{
		width: 60%;
		margin-bottom: 30px;
	}
}
.darksoul{
	background: #333;
	border-radius: 4px;
	margin: 20px 0;
	text-align: center;
	color: #fff;
	h3{
		color: $acidgreen;
		font-size: 33px;
		margin: 45px 0;
	}
	h4{
		font-size: 24px;
		margin: 10px 0;
	}
	p{
		min-height: 65px;
	}
	.buton{
		background: $acidgreen;
		text-transform: uppercase;
		font-family: 'ManifoldCF-ExtraBoldOblique';
		display: inline-block;
		width: 80%;
		padding: 10px;
		font-size: 18px;
		margin: 0 auto;
		color: #333;
		text-decoration: none;
		transition: all 0.35s ease-in-out;
		&:hover{
			background: lighten($acidgreen,10%);
			color: #fff;
		}
		@media (min-width: 768px){
			width: 60%;
		}
	}
	&.sts-int{
		border-radius: 0;
		margin: 0;
	}
}
.getstart{
	h3 {
		font-family: 'ManifoldCF-ExtraBoldOblique';
		font-size: 28px;
		text-align: center;
		text-transform: uppercase;
	}
}
hr.verd{
	border-top: 2px solid $acidgreen;
}
hr.gray{
	border-top: 1px solid #ccc;
}
.ready{
	h3{
		text-transform: uppercase;
		font-family: 'ManifoldCF-ExtraBoldOblique';
		font-size: 28px;
		text-align: center;
		margin-bottom: 25px;
	}
	p{
		margin: 0;
		strong{
			font-family: 'ManifoldCF-ExtraBoldOblique';
		}
	}
}
.phase{
	text-align: center;
	margin-bottom: 20px;
	@media (min-width: 992px){
		margin-bottom: 0;
	}
	h4 {
		font-size: 28px;
		padding-top: 15px;
	}
	hr {
		border-top: solid 1px #C5C5C5;
		width: 85%;
	}
	.terrain {
		font-family: 'ManifoldCF-ExtraBoldOblique';
		font-size: 14px;
		margin-bottom: 15px;
	}
	.ph_level {
		font-size: 12px;
		color: #666;
		margin-bottom: 10px;
		text-transform: uppercase;
		img {
			height: 13px;
			vertical-align: text-bottom;
		}
	}
	.ph1,.ph2,.ph3,.ph4 {
		display: block;
		&:hover {
			background: #f1f1f1;
		}
	}
	.ph1 {
		background: #EEEEEE;
	}
	.ph2 {
		background: #E7E7E7;
	}
	.ph3 {
		background: #DEDEDE;
	}
	.ph4 {
		background: #D5D5D5;
	}
}
.ban-sts-btns {
	background: #F7F7F7;
	padding: 10px 20px;
	h3{
		font-size: 28px;
	}
	a {
		background: $acidgreen;
		display: inline-block;
		padding: 8px 24px;
		font-size: 18px;
		font-family: 'ManifoldCF-ExtraBoldOblique';
		text-transform: uppercase;
		margin-top: 20px;
	}
	@include respond-to(notebook) {
		padding: 40px;
	}
}
.listenrb{
	font-family: 'ManifoldCF-ExtraBoldOblique';
	font-size: 18px;
	strong{
		font-size: 28px;
	}
}
.gridath{
	.cardath{
		background: #EEE;
		padding: 20px;
		@media (min-width: 992px){
			min-height: 385px;
		}
		.wpmi{
			img{
				border-radius: 100%;
				width: 100px;
				height: auto;
			}
			.loca{
				font-family: 'ManifoldCF-ExtraBoldOblique';
				font-size: 20px;
				strong{
					font-size: 28px;
				}
			}
		}
		@media (min-width: 1280px){
			padding: 30px;
		}
	}
}
.linests1{
	.logo{
		float: left;
		margin: 0 20px 20px 0;
		max-width: 200px;
	}
	p{
		margin: 0;
		line-height: 35px;
	}
}


.goteamsts{
	padding: 30px 0;
	h3{
		font-size: 30px;
	}
	p{
		font-size: 18px;
		color: #929292;
	}
}
.ststshead{
	text-align: center;
	background: url("../img/sts/emosts_2x.jpg") no-repeat center center/cover;
	padding: 3rem 1rem;
}

.moreadvise {
	background: #F7F7F7;
	padding: 10px 20px;
	font-size: 24px;
	line-height: 1.2;
	margin: 50px 0;
	text-align: center;
	@include respond-to(notebook) {
		padding: 40px 150px;
	}
	h4 {
		font-weight: 700;
		font-size: 28px;
	}
	a {
		background: $acidgreen no-repeat 20px center;
		display: block;
		width: 60%;
		font-weight: 700;
		padding: 20px;
		font-size: 24px;
		border-radius: 4px;
		margin: 0 auto;
	}
}
.ststsheadint {
	text-align: center;
	background: url("../img/sts/bgstsint.jpg") no-repeat center top/cover;
	padding: 3rem;
	h1 {
		font-size: 40px;
		font-weight: 700;
		margin-bottom: 40px;
	}
	.loghi{
		width: 60%;
	}
}
@include respond-to(notebook) {
	.rowinmid {
		margin: 0 40px;
		div:first-child {
			border-right: solid 1px rgba(#ccc,0.7);
		}
	}
}
.bulwbw {
	font-size: 20px;
	padding: 0 20px;
	img {
		margin-bottom: 20px;
	}
	@include respond-to(notebook) {
		padding: 0 40px;
	}
}



.rowl1,.rowl2 {
	margin: 10px 0;
	padding: 10px 0;
	@include respond-to(notebook) {
		.col-lg-6 {
			padding: 50px;
		}
	}
	.phtit {
		font-size: 28px;
		font-weight: bold;
		@include respond-to(notebook) {
			font-size: 40px;
		}
		&--2 {
			display: flex;
			text-transform: uppercase;
			font-size: 24px;
			align-items: flex-start;
			line-height: 1;
			padding-top: 15px;
			margin-bottom: 45px;
			@media (min-width:992px) {
				margin-bottom: 0px;
			}
			@media (min-width:1200px) {
				font-size: 28px;
			}
			span {
				font-family: $fontf--extrabol;
				font-size: 40px;
				margin-right: 10px;
				position: relative;
				top: -3px;
			}
		}
	}
	.phdesc {
		font-size: 22px;
		@include respond-to(notebook) {
			font-size: 32px;
		}
	}
}
.rowl1 {
	background: #F7F7F7;
	&--2 {
		background: transparent;
	}
}
.rowl2 {
	text-align: right;
}
#lockpage,#ecomm {
	width: 90%;
	background: #F7F7F7;
	@include respond-to(notebook) {
		width: 800px;
	}
}
.ststsemoint {
	background: url("../img/sts/bgemo_basics3.jpg") no-repeat center bottom/cover;
	padding: 20px;
	font-size: 16px;
	@include respond-to(notebook) {
		padding: 80px 40px;
	}
	@include respond-to(full) {
		padding: 120px 70px;
		.col-md-6 {
			padding-right: 40px;
		}
	}
	h1 {
		color: $acidgreen;
		text-transform: uppercase;
		font-size: 42px;
	}
	.announce {
		font-size: 18px;
		margin-bottom: 30px;
	}
}
.darkband {
	background: #333;
	color: #fff;
	font-size: 18px;
	padding: 45px 0;
	h3 {
		color: $acidgreen;
		font-size: 25px;
	}
}

.bkg-black{
	background-color: #e9e9e9;
	.nightshift_pg{
		@media (min-width: 1024px){
			background: url("../img/bkgblue.png") no-repeat left center;
			background-size: contain;
		}
		.bkgblue{
			p{
				color: #666;
				font-size: 20px;
				font-family: 'ManifoldCF-ExtraBold';
			}
			.blue{
				color: #1788C3;
				font-size: 40px;
				margin-bottom: 30px;
				line-height: 100%;
			}
		}
	}
}
/* choosing */
.where{
	h4{
		font-size: 28px;
		text-transform: uppercase;
	}
	p{
		font-size: 20px;
		font-family: 'ManifoldCF-ExtraBold';
	}
	img{
		width: 150px;
		height: auto;
		margin: 0;
	}
}
.box_find{
	.txt_find{
		.t_find{
			font-family: 'ManifoldCF-ExtraBold';
		}
	}
	margin-bottom: 20px;
}
.img_author{
	a{
		display: inline-block;
		img{
			max-width: 250px;
			margin-right: 30px;
		}
	}
}

.list_prod_sts{
	a{
		display: block;
		.boximg{
			overflow: hidden;
			img{
				margin: 0 auto;
				transition: all 0.35s ease-in-out;
				width: 80%;
			}
		}
		.skate-list-name{
			position: relative;
			display: inline-block;
		}
		&:hover{
			.boximg{
				img{
					transform: scale(1.1);
				}
			}
			@media (min-width: 992px){
				.skate-list-name::before{
					width: 100%;
				}
			}
		}
		@media (min-width: 992px){
			.skate-list-name::before{
				content: '';
				position: absolute;
				bottom: -3px;
				left: 0;
				overflow: hidden;
				width: 0;
				border-bottom: 2px solid #B6C62C;
				transition: width 0.5s ease;
				z-index: -1;
				transform: skewX(-30deg);
			}
		}
	}
}
.std-font {
	font-size: 17px;
	p {
		font-size: 17px;
	}
	h5 img {
		vertical-align: sub;
	}
	a.green {
		color: $acidgreen;
		font-weight: bold;
	}
}
.std-time {
	color: #989EA3;
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 15px;
	background: #E6E6E6;
	line-height: 35px;
}
.std-act {
	color: #11171F;
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 18px;
	line-height: 1.1;
}
.weektrain {
	.col-md-3 {
		border-right: 3px solid #fff;
		padding-bottom: 1rem;
	}
}




.bigtit{
	font-size: 50px;
}
.lesson{
	background: #f7f7f7;
	.number{
		color: $acidgreen;
		font-size: 100px;
	}
	.tit{
		font-size: 42px;
	}
	hr{
		color: #666;
		background-color: #666;
	}
	p{
		font-size: 16px;

	}
	ul{
		li{
			font-family: 'ManifoldCF-ExtraBoldOblique', sans-serif;
			list-style: disc;
			margin-bottom: 20px;
		}
	}
	.perf{
		font-size: 16px;
	}
	&.list{
		p{
			font-size: 14px;
		}
	}
	.numlist{
		font-size: 14px;
		padding-left: 15px;
	}
}



.trainsts {
	border-top: solid 2px $acidgreen;
	border-bottom: solid 2px $acidgreen;
	margin: 2.5rem 0;
	&.steph{
		border-bottom: none;
	}
	.buton{
		background: $acidgreen;
		text-transform: uppercase;
		font-family: 'ManifoldCF-ExtraBoldOblique', sans-serif;
		display: inline-block;
		padding: 10px;
		font-size: 18px;
		margin: 0 auto;
		color: #333;
		text-decoration: none;
		transition: all 0.35s ease-in-out;
		&:hover{
			background: lighten($acidgreen,10%);
			color: #fff;
		}
	}
	.col-md-3,.col-md-7 {
		padding: 2rem 0;
	}
}