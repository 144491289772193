.contact_pg{

	form{
		.form-check-input{
			position: relative;
			margin: 0;
			padding: 0;
		}
		.form-control{
			background-color: $bkg-gray-l;
			background-clip: unset;
			border-radius: 0;
			border: 1px solid $bkg-gray-m;
		}
		.button-label-big{
			background-color: $green-c;
			border-radius: 0;
			text-transform: uppercase;
			padding: 10px 60px;
		}
	}
	&.contact-gate{
		.linksx, .linkdx{
			display: block;
			padding: 20% 0;
			background: #EEE;
			text-align: center;
			transition: all .5s;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 20px;
			img{
				display: block;
				height: 60px!important;
				margin: auto 0 30px;
				transition: all .5s;
			}
			&:hover{
				background: #C3D52E;
				img{
					height: 70px!important;
					margin: auto 0 20px;
				}
			}
		}
	}




	#ffSection0 .ff-item-row:first-child{width: 98% !important;margin: 0 1% 0 1% !important;}
	#ffSection0 .ff-item-row{
		width: 98% !important; display: inline-block; margin: 0 1% 0 1%;
		@media (min-width: 768px) {
			width: 48% !important;

		}
	}
	#ffSection1 .ff-item-row, #ffSection2 .ff-item-row, .ff-footer-row{
		width: 98% !important;
		margin: 0 1% 0 1% !important;
	}
	.ff-submit-btn{
		margin: 0;
	}




	.formstack{


		.ff-section-header{font-size: 1.6rem; text-transform: uppercase; font-family: $fontf--boldob;}
		.ff-btn-submit{
			background-color: #C3D52E;
			border-radius: 0;
			text-transform: uppercase;
			padding: 10px 60px;
			font-family: $fontf--boldob;
			font-size: 18px;
			line-height: 1.06;
			text-align: center;
			border: none;
		}
		#dvFastForms .btnDiv input{
			margin-left: 0;
		}

		margin-top: 100px;
		@media (min-width: 768px) {
			margin-top: 40px;
		}
		outline: 0 !important;

		.ff-type-text, .ff-singlepicklist, .ff-multipicklist{border: none; background: #EEE; padding: 5px!important; width: 100%; min-height: 36px !important; outline: 0 !important;}
		.ff-col-2{float: none!important; max-width: none!important;}
		.ff-textarea{border: none !important; background: #eee!important; width: 100%; height: 100px !important; padding: 5px !important;outline: 0 !important;}
		.ff-group-row{margin-bottom: 20px !important;}
		.ff-item-row:first-child{width: 100% !important;}
		.ff-col-1{float: none}
		#dvFastForms .ff-field-col{padding: 0}
		.ff-form-main{width: 100% !important; margin: auto; max-width: none; margin-bottom: 100px!important;}
		.ff-required-mark{color: #999}
		#dvFastForms .ff-group-row{max-width: none}
		#dvFastForms .btnDiv{text-align: left}

		.select2-choices{padding: 0 !important;}
		.select2-choices li{margin: auto !important;}
		.select2-choices{border: none !important;}
		.select2-drop, .select2-results{background: #333 !important;}
		.select2-results .select2-highlighted{//background: $primary !important;}

		.ff-fileupload-drop-area{border-radius: 0; background: #333 !important; padding: 5px !important;}

		.ff-invalid-msg{margin-left: 10px}



		#ffSection1 .ff-item-row, #ffSection2 .ff-item-row,#ffSection3 .ff-item-row, .ff-footer-group{width: 96%; display: block; margin: 0 2%}
			#dvFastForms .btnDiv input{margin: 20px auto}
			.ff-footer-group{border-top: 1px solid #333}
			.ff-btn-submit{
				transition: all 0.2s ease;
				padding: .5rem 1rem !important;
				font-size: 1.25rem !important;
				line-height: 1.5 !important;
				border-radius: 2px !important;
				font-weight: 500!important;
				color: #000 !important;
				&:hover{
					background: #000!important;
				}
			}

		}

	}






}

//Register
.joinpg{
	.join{
		h2{
			border-top: 1px solid #cccccc;
			text-transform: uppercase;
			margin-top: 30px;
			margin-bottom: 20px;
			padding-top: 40px;
		}
		&.firstj{
			h2{
				border: none;
				padding-top: 20px;
			}
		}
	}
}