$bbm_blue : #035EAA;

.bbm-landing {
  margin-top: 80px;
  br {
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  &__top {
    background-image: url('../img/header-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 60px 0;
    h2 {
      font-size: 50px;
      color: #FFF;
      margin: 30px 0 0 0;
    }
    h1 {
      font-size: 30px;
      color: #FFF;
      margin: 15px 0 30px 0;
      padding-bottom: 30px;
      position: relative;
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate3d(-50%,0,0);
        background: #FFF;
        height: 1px;
        width: 30%;
        max-width: 170px;
      }
    }
    h3 {
      font-size: 24px;
      color: #FFF;
      margin: 0px;
    }
    &-logo {
      max-width: 170px;
    }
  }
  &__actions {
    padding: 75px 0;
  }
  &__action {
    text-align: center;
    margin-top: 60px;
    &:first-of-type {
      margin-top: 0px;
    }
    @media (min-width: 992px) {
      margin-top: 0px;
    }
    &-content {
      font-size: 18px;
      margin: 30px 0 45px;
      padding: 0 15px;
      min-height: 120px;
      @media (min-width: 1200px) {
        padding: 0 45px;
      }
      b {
        color: $bbm_blue;
        font-family: 'ManifoldCF-Bold';
      }
    }
    &-btn {
      background: $bbm_blue;
      color: #FFF;
      font-size: 16px;
      line-height: 1.25;
      padding: 15px 30px;
      display: inline-block;
      font-family: 'ManifoldCF-Bold';
      width: calc(100% - 30px);
    }
    &-condition {
      font-size: 12px;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 15px;
      padding: 0 30px;
      @media (min-width: 1200px) {
        padding: 0 90px;
      }
    }
  }
  &__interview {
    padding: 90px 60px;
    background: #EFEFEF;
    h4 {
      color: #1788C3;
      font-size: 20px;
      margin: 30px 0;
    }
    &-content {
      max-height: 500px;
      overflow: auto;
    }
  }
  &__form {
    border-bottom: 1px solid #EFEFEF;
  }
  &__highlights {
    padding: 90px 15px;
    h4 {
      text-align: center;
      font-size: 30px;
    }
    h5 {
      text-align: center;
      font-size: 20px;
      font-family: 'ManifoldCF-RegularOblique';
    }
    .imgprd {
      margin: 0 auto;
      transition: all 0.35s ease-in-out;
      width: 80%;
    }
    .text-left {
      margin-top: 5px;
    }
    .skate-list-name {
      position: relative;
      display: inline-block;
      margin-bottom: 5px;
      &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        overflow: hidden;
        width: 0;
        border-bottom: 2px solid #B6C62C;
        transition: width 0.5s ease;
        z-index: -1;
        transform: skewX(-30deg);
      }
    }
    a {
      &:hover {
        .imgprd {
          transform: scale(1.05);
        }
        .skate-list-name {
          &:after {
            width: 100%;
          }
        }
      }
    }
    .promo-cond {
      font-size: 16px;
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid #EFEFEF;
      border-bottom: 1px solid #EFEFEF;
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: 'ManifoldCF-RegularOblique';
      text-align: center;
    }
  }
}