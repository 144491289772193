.main-banner{
    display:none;
    background: hsla(0,0%,9%,.8);
    left: 0;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    transition: opacity .3s;
    &_box{
        background: #fff;
        //margin: 48px;
        position: relative;
        @include media-breakpoint-up(lg){
            margin: 0;
        }
    }
    &_dark-close{
        cursor: pointer;
        display: none;
        position: absolute;
        right: 16px;
        top: 16px;
        transition: all .5s;
        z-index: 999999;
        @include media-breakpoint-up(sm){
            display: block;
        }
    }
    &_white-close{
        cursor: pointer;
        bottom: calc(100% + 16px);
        left: 50%;
        position: absolute;
        transform: translate3d(-50%,0,0);
        display: block;
        z-index: 999999;
        @include media-breakpoint-up(sm){
            display: none;
        }
    }
    &_btn-cta {
        font-family: "ManifoldCF-ExtraBoldOblique";
        font-size: 15px;
        color: #11171f;
        line-height: 1;
        padding: 12px 18px;
        background: #C3D52E;
        display: inline-block;
        &:hover{
            transform: scale(1.05);
        }
        @include media-breakpoint-up(xl){
            padding: 16px 48px;
        }
    }
    &_title{
        font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
        color: $anthracite-c;
        font-size: 32px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 12px;
        @include media-breakpoint-up(lg){
            font-size: 42px;
        }
    }
    &_text{
        font-size: 16px;
        font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
        line-height: 1;
        color: $anthracite-c;
        @include media-breakpoint-up(lg){
            font-size: 28px;
        }
    }
    &_img{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    &_img-desk{
        @include media-breakpoint-down(sm){
            max-height: 285px;
        }
    }
}
