
#popup-twister{
	background-color: #FFF;
	padding: 3% 1%;
	width: 800px;
	min-height: 300px;
	text-align: center;
	font-family: 'ManifoldCF-Regular', sans-serif;
	h3{
		font-size: 26px;
		text-transform: uppercase;
	}
	strong{
		font-family: 'ManifoldCF-Bold', sans-serif;
	}
	.logo{
		width: 60px;
      	margin-bottom: 30px;
	}
	.tools{
		width: 400px;
		margin:30px 0;
	}
	a{
		background-color: #C3D52E;
		display: block;
		padding: 15px;
		color: #333;
		font-size: 20px;
		font-family: 'ManifoldCF-Bold', sans-serif;
		max-width: 300px;
		margin: auto;
		text-transform: uppercase;
		&:hover{
			background-color: #323e47;
			color: #FFF;
		}
	}

}


#close-fullImage{
	position: fixed;
	top: 60px;
	right: 60px;
	display: none;
	opacity: 0;
	transform: translateY(-100%);
	width: 60px;
	transition: all .5s;
	z-index: 9999;
	svg {
		path {
			transition: all .5s;
		}
	}
	&.st-1{
		display: block;
	}
	&.st-2{
		opacity: 1;
		transform: translateY(0);
		&:hover{
			svg{
				path {
					fill: #C3D52E;
				}
			}
		}
	}
}

.box-pagsped{
	background-color: #F6F6F6;
	margin: 0 0px;
	.text-center{
		padding: 40px 30px;
		div{
			min-height: 40px;
			margin-bottom: 20px;
		}
		p{
			font-size: 12px;
			font-family: 'ManifoldCF-Bold', sans-serif;
			line-height: 16px;
			margin: 0;
		}
	}
}

/* prev next */
.prevnext{
    //&,.promo{
    //    margin-top: 94px;
    //    @media screen and (min-width: 840px){
    //        margin-top: 80px;
    //    }
    //    @media screen and (min-width: 992px){
    //        margin-top: 134px;
    //    }
    //    @media screen and (min-width: 1061px){
    //        margin-top: 115px;
    //    }
    //}
	margin-top: 75px;
	border-bottom: 1px solid #dfdfdf;
	.home_list{
		a{
			svg path{
				transition: $transition;
			}
			&:hover{
				svg path{
					fill: $anthracite-c;
				}
			}
		}
	}
	a{
		img{
			width: 45px;
			height: 45px;
		}
		p{
			transition: all 0.35s;
		}
		&:hover{
			p{
				color: $green-c;
			}
		}
	}
	&.awayUp{
		transform: translateY(calc(-100% - 175px));
	}
}

#box_styc{
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

/* col dx */
.prodDetailBox{
	position: relative;
	.detail{
		.text{
			margin-top: 50px;
			transition: all .5s;
			overflow: hidden;
			position: relative;
			&.away {
				left: 65%;
				transition: all .5s;
				position: absolute;
				&.step-2 {
					left: calc(100% + 60px);
					opacity: 0;
				}
			}
			@media (min-width: 768px){
				float: right;
				width: 45%;
				min-height: 600px;
			}
		}
	}
}

.feature{
	padding-top: 16px;
	margin-top: 32px;
	border-top: 1px solid #DDDDDD;
	img{
		height: 40px;
	}

}

.prod-det-buttons {
	a {
		display: flex;
		align-items: center;
		background: #E9E9E3;
		height: 40px;
		width: 100%;
		justify-content: center;
		font-family: $fontf--boldob;
		font-size: 12px;
		color: $anthracite-c;
		text-align: center;
		line-height: 1;
		&:hover {
			transform: scale(1.055);
		}
	}
    svg {
        margin-right: 8px;
    }
}

.descr_prod{
	margin-top: 16px;
	margin-bottom: 32px;
	div{
		max-height: 225px;
		overflow: hidden;
		transition: all 1s cubic-bezier(0, 1, 0, 1);
		@media (min-width: 768px){
			max-height: 135px;
		}
		&.view{
			max-height: 1000px;
			transition: all 1s ease-in-out;
		}
	}
}
.dati-prod {
	padding-bottom: 16px;
	border-bottom: 1px solid #ddd;
}
.box_compra{
	margin: 32px 0 0 0;
	.p-find-size {
		&:hover {
			opacity: .75;
		}
		&-contact {
			font-family: $fontf--bold;
			margin-left: 8px;
			font-size: 13px;
			color: $anthracite-s;
			line-height: 1;
			position: relative;
			top: 1px;
			&:hover {
				opacity: .75;
			}
		}
		&-wrap {
			margin-top: 16px;
			display: flex;
			align-items: center;
			span {
				margin-left: 8px;
			}
		}
		display: flex;
		align-items: center;
		font-family: $fontf--bold;
		font-size: 12px;
		color: $anthracite-c;
		text-align: center;
		line-height: 1;
		img {
			margin-right: 8px;
		}
	}
	.select-av-size {
		margin-bottom: 16px;
	}
	.quantibox {
		margin-bottom: 20px;
		label {
			color: #323e47;
			font-family: 'ManifoldCF-BoldOblique',sans-serif;
			font-size: 15px;
			line-height: 1.2;
			margin: 0;
		}
	}
	form{

		padding-top: 24px;
		border-bottom: 1px solid #ddd;
		padding-bottom: 15px;

		#quanti{
			color: #333333;
			opacity: 1;
			font-size: 14px;
			width: 70px;
			min-width: 50px;
			//padding: 11px 12px;
			border-width: 1px;
			border-style: solid;
			border-color: #CCCCCC;
			border-radius: 3px;
			//vertical-align: middle;
			line-height: 1;
			display: inline-block;
			resize: none;
			margin: 0 0 0 15px;
		}

		.sel-size-wrap {
			position: relative;
			height: 100%;
			select {
				-webkit-appearance:none;
				border-radius: 0;
			}
			span {

				content: '';
				background-image: url(../img/2021/select-size-arrow.svg);
				width: 34px;
				height: 16px;
				position: absolute;
				right: 2px;
				background-repeat: no-repeat;
				background-color: #FFF;
				top: 50%;
				transform: translate3d(0, -50%, 10px);
				background-position: left center;
				z-index: 10;
				pointer-events: none;

			}
		}
		#sel_size{
			list-style: none;
			display: inline-flex;
			width: 100%;
			padding: 0;
			position: relative;
			flex-wrap: wrap;
			margin-bottom: 15px;
            gap: 8px;
			li{
				text-align: center;
				max-width: 10%;
				min-width: 49px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #E6E8E9;
                border-radius: 4px;
                padding: 16px 8px;
                transition: all 0.35s ease-in-out;
                flex: 0 1 calc(25% - 8px);
				&.taglia--unica {
					max-width: 100%;
					flex: 0 0 auto;
					padding: 16px 8px;

				}
				a{
					display: block;
					position: relative;
					&:hover .infodispo{
						display: block;
					}
				}
				&:hover{
					background-color: rgba(195, 213, 46, 0.5);
				}
				&.sel{
					background-color: $anthracite-s;
					a{
						color: $green-c;
					}
				}
				&.nondisp{
					background-color: rgba(69, 69, 69, 0.1);
					a{
						color: #ccc;

						cursor: default;
						position: relative;
						&:hover .infodispo{
							display: block;
						}
					}
				}
				.infodispo{
					display: none;
					color: #FFF;
					position: absolute;
					background-color: #333;
					border-radius: 3px;
					top: -20px;
					left:5px;
					padding: 2px 10px;
					font-size: 11px;
					line-height: 12px;
				}
			}
		}
		.button-label-big{
			cursor: pointer;
			padding: 15px 50px;
			text-transform: uppercase;
		}
		.btn_compra{
			background-color: $anthracite-c;
			transition: all 0.3s linear;
			border: none;
			&:hover{
				background-color: $green-c;
				cursor: pointer;
			}
		}
		.go_cart{
			background-color: rgba(50, 62, 71, 0.7);
			display: block;
		}
	}
}
.tre_btn{
	a{
		min-height: 52px;
		img{
			margin-right: 5px;
		}
		&.dis{
			background-color: #E9E9E9 !important;
			color: #bbb;
			cursor: no-drop;
			img{
				opacity: 0.3;
			}
		}
		&:hover{
			background-color: rgba(195, 213, 46, 0.5);
		}
	}
}
.dice{
	padding-left: 20px;
	list-style: url(../img/ico-check.svg);
	li{
		a{
			&:hover{
				color: $green-c;
			}
		}
	}
}
.slide-info{
	position: relative;
	overflow: hidden;
	.owl-item{
		text-align: center;
		padding: 30px 60px;
		background-color: $bkg-gray-l;
		img{
			width: auto;
			margin: auto;
		}
		p{
			font-size: 14px;
			margin: 15px 0 0 0;
			font-family: 'ManifoldCF-RegularOblique';
			min-height: 38px;
		}
	}
	.owl-nav{
		button{
			position: absolute;
			top: 50%;
			margin-top: -8.5px;
			line-height: 0 !important;
			outline: none !important;
			&.owl-prev{
				left: 20px;
			}
			&.owl-next{
				right: 20px;
			}
		}
	}
    .item-info {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 1rem;

        svg {
            flex-shrink: 0;
        }

        p {
            margin:0;
        }
    }
}
.target_line{
	.line_tg{
		.line{
			background-color: #d5d5d0;
			flex: 0 0 32.5%;
			max-width: 32.5%;
			height: 5px;
			&.active{
				background-color: $green-c;
			}
		}
		&.for_line{
			.line{
				flex: 0 0 24%;
				max-width: 19%;
			}
		}
	}
	p{
		text-transform: uppercase;
	}
	.experience-list-item-date{
		margin-bottom: 0;
	}
}
.techs{
	img{
		width: 75px;
		height: auto;
		flex: 0 0 12%;
	}
	p{
		margin: 0;
	}
	.col-12:last-of-type{
		.border-bottom{
			border: none !important;
		}
	}
}

.bord-top-green{
	border-top: 3px solid #E9E9E3;
	padding-top: 20px;
	margin-bottom: 30px;
}

/* prodotti compare */
.comp_slide{
	.owl-stage{
		&.solomenodiquattro{
			@include media-breakpoint-up(md){
				transform: translateX(-50%) !important;
				left: 50%;
			}
		}
	}
	.owl-item{
		&:nth-of-type(1){
			ul{
				border: none;
			}
		}
	}
	.owl-dots{
		text-align: center;
		button{
			outline: none;
			box-shadow: none;
			span{
				display: inline-block;
				height: 3px;
				width: 20px;
				background-color: $bkg-gray-m;
				margin: 0 5px;
			}
			&.active{
				span{
					background-color: $green-c;
				}
			}
		}
	}
	.item_cor{
		a{
			display: block;
			position: relative;
			.comparato{
				position: absolute;
				top: 0;
				right: 0;
				width: 20px;
			}
			.img-fluid{
				width: 60%;
				margin: auto;
			}
		}
		ul{
			border-left: 1px solid #bbb;
			margin-top: 30px;
			li{
				min-height: 65px;
				padding: 18px 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				&:nth-child(odd){
					background-color: #f6f6f6;
				}
				.line_comp{
					margin-bottom: 10px;
					.line{
						background-color: #d5d5d0;
						height: 5px;
						flex: 0 0 24%;
						max-width: 19%;
						&.active{
							background-color: $green-c;
						}
					}
				}
				p{
					margin-bottom: 0;
					text-transform: uppercase;
				}
			}
		}
		&.compare{
			h4{
				color: $green-c;
			}
		}
	}
}
/* prodotti correlati */
.related{
	&.op-0{
		opacity: 0;
	}
	.rel_slide{
		position: relative;
		overflow: hidden;
		a{
			display: block;
			.over-hid{
				overflow: hidden;
			}
			img{
				transition: $transition;
			}
			&:hover{
				img{
					transform: scale(1.05);
				}
			}
		}
		.owl-dots{
			text-align: center;
			button{
				outline: none;
				box-shadow: none;
				span{
					display: inline-block;
					height: 3px;
					width: 20px;
					background-color: $bkg-gray-m;
					margin: 0 5px;
				}
				&.active{
					span{
						background-color: $green-c;
					}
				}
			}
		}
	}
}

/* video */
.videos{
	.wrapper_video{
		width: 100%;
		height: 100%;
		.h_iframe{
			position: relative;
			.ratio{
				width: 100%;
				height: auto;
			}
			iframe{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

/* col sx */
.prodDetailBox{
	#accordion {
		margin-top: 32px;
		.card {
			border-left: none;
			border-right: none;
			border-bottom: none;
			border-radius: 0;
		}
		.card-header {
			border: none;
			background: none;
			padding: 0;
		}
		.card-body {
			padding-left: 0;
			padding-bottom: 2.5rem;
		}
		button {
			background-image: url("../img/2021/ico-close.svg");
			background-repeat: no-repeat;
			background-position: right 16px center;
			text-transform: uppercase;
			padding: 12px 0;
			display: block;
			width: 100%;
			text-align: left;
			font-family: $fontf--extraboldob;
			font-size: 20px;
			color: #989EA3;
			letter-spacing: 0;
			line-height: 1;
			&:hover {
				color: $anthracite-c;
			}
			&.collapsed {
				background-image: url("../img/2021/ico-plus-filters.svg");
			}
		}
	}
	.image{
		position: relative;
		width: 55%;
		float: left;
		transition: all .5s;
		#immagine_attuale{
			width: 80%;
			max-height: calc(100vh - 120px);
			object-fit: contain;
			object-position: center;
			transition: all .5s;
		}
		.gallery-altreviste{
			@media (min-width: 992px){
				position: absolute;
				left: 0;
				top: 10%;
			}
			.img_att{
				width: 45px;
				height: 45px;
				border: 1px solid #ddd;
				margin: 0 2px;
				@media (min-width: 992px){
					display: block;
					margin: 0 0 5px 0;
				}
				@media (min-width: 1400px){
					width: 60px;
					height: 60px;
				}
			}
			a{
				display: inline-block;
				width: 45px;
				height: 45px;
				border: 1px solid #ddd;
				transition: $transition;
				margin: 0 2px;
				@media (min-width: 992px){
					display: block;
					margin: 0 0 5px 0;
				}
				@media (min-width: 1400px){
					width: 60px;
					height: 60px;
				}
				img{
					width: 100%;
				}
				&:hover{
					opacity: 0.7;
				}
			}
			.anc_vid{
				cursor: pointer;
				svg{
					g{
						transition: all 0.35s;
					}
				}
				&:hover{
					svg{
						g{
							fill: $anthracite-s;
						}
					}
				}
			}
		}
		&.fullWidth {
			width: 100%;
			height: 100vh;
			#immagine_attuale {
				transform: scale(1.25);
			}
		}
		.big-image {
			transition: all .5s;
			text-align: center;
			&.pullUp {
				transform: translateY(-120px);
			}
		}
	}
	.slide_detmod{
		.owl-dots{
			text-align: center;
			button{
				span{
					display: inline-block;
					height: 3px;
					width: 20px;
					background-color: $bkg-gray-m;
					margin: 0 5px;
				}
				&.active{
					span{
						background-color: $green-c;
					}
				}
			}
		}
	}
}
/* contenuti correlati */
.relatedCont{
	&.op-0{
		opacity: 0;
	}
	.item{
		a{
			display: block;
			overflow: hidden;
			position: relative;
			img{
				transition: $transition;
				width: 100%;
			}
			.text{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				background-image: linear-gradient(to bottom, rgba(17, 23, 31, 0), rgba(17, 23, 31, 0.8));
				text-align: center;
				.icn_play{
					width: 45px;
					height: 45px;
					margin-bottom: 20px;
				}
				p{
					text-align: left;
				}
			}
			&:hover{
				img{
					transform: scale(1.05);
				}
			}
		}
	}
}

.videoReview{
	.wrapper_video{
		width: 100%;
		height: 100%;
		.h_iframe{
			position: relative;
			.ratio{
				width: 100%;
				height: auto;
			}
			iframe{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

.productPriceSticky {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 8999;
	background: #FFF;
	padding: 12px 0;
	border: 1px solid #DFDFDF;
	&__buy {
		font-family: $fontf--extraboldob;
		font-size: 14px;
		color: #323E47;
		line-height: 1;
		background: $green-c;
		display: flex;
		align-items: center;
		padding: 10px 30px;
		svg {
			margin-right: 8px;
			max-width: 18px;
		}
	}
	&__title {
		font-family: $fontf--extraboldob;
		font-size: 20px;
		color: $anthracite-s;
		letter-spacing: 0;
		text-align: left;
		line-height: 1;
		margin: 0;
	}
	&__price {
		font-family: $fontf--bold;
		font-size: 20px;
		color: $anthracite-s;
		letter-spacing: 0;
		text-align: left;
		opacity: .75;
	}
	&__row {
		display: flex;
		align-items: center;
	}
	&__left {
		display: none;
		@include media-breakpoint-up(lg) {
			width: 55%;
			display: block;
		}
	}
	&__right {
		@include media-breakpoint-up(lg) {
			width: 45%;
		}
	}
}

.border-bottom-locally {
	border-bottom: 1px solid #DDDDDD;
	margin-bottom: 16px !important;
}
