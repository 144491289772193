// menu
.expMenu{
	margin-top: 75px;
	.a-sticky-nav {
		&:before {
			left: 0;
		}
		&:hover {
			&:before {
				width: 100%;
			}
		}
	}
	a{
		display: flex;
		align-items: center;
		svg{
			margin-right: 10px;
		}
		p{
			margin-bottom: 0;
		}
		&.sel{
			svg path{
				fill: $green-c;
				opacity: 1;
			}
			p{
				color: $green-c;
			}
		}
	}
	ul{
		display: none;
		@media (min-width: 992px){
			display: flex;
		}
		&.open{
			margin-top: 15px;
			li{
				margin: 10px 0;
			}
		}
	}
}

.expMenuUsa {
    @extend .expMenu;
    margin-top: 110px;
}

// HP experience
.box-relative{
	position: relative;
	img{
		transform: scale(1);
		transition: all 13s cubic-bezier(0.23,1,0.32,1);
	}
	.box-absolute{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-image: linear-gradient(to bottom, rgba(17, 23, 31, 0), rgba(17, 23, 31, 0.8));
		padding: 50px 15px 0 15px;
	}
	&.d-block{
		background-color: $anthracite-s;
		overflow: hidden;
		&:hover{
			img{
				opacity: 0.3;
				transform: scale(1.1);
			}
		}
	}
}
.box_netflix.netAtl {
	background-image: none;
    @media (min-width: 768px){
        //padding-left: 40px;
        padding: 32px 0 64px 0;
    }
	.focus_team {
	}
	.owl_net .owl-nav .owl-prev{
		left: -40px;
	}
    .slick-next {
        right: 10px;
    }
}
.due_bn{
	a{
		position: relative;
		display: block;
		overflow: hidden;
		&::before{
			position: absolute;
			top: 0;
			left: -75%;
			z-index: 9999;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.05) 100%);
			transform: skewX(-25deg);
		}
		img{
			transition: all 0.35s ease-in-out;
		}
		&:hover{
			&::before{
				animation: shine .75s;
			}
			img{
				opacity: 0.95;
			}
		}
	}
}

// News
.maintextExp{
	a{
		color: $green-s;

	}
	strong{
		font-family: 'ManifoldCF-ExtraBoldOblique', sans-serif;
	}

}
.expNews{
	.row{
        @media (min-width: 768px) {
            margin-bottom: 2rem;
        }
		.doble{
			a:first-of-type{
				margin-bottom: 10px;
			}
		}
		.col-md-8{
			.experience-list-item-title{
				font-family: 'ManifoldCF-ExtraBoldOblique', sans-serif;
				font-size: 35px;
			}
		}
        .experience-list-item-title {
            font-size: 16px;
        }
	}
    .slick-dots li button {
        padding: 0;
        background: #e9e9e3;
    }
}
.buttonLoad{
	.loadmore{
		font-family: 'ManifoldCF-ExtraBoldOblique', sans-serif;
		font-size: 18px;
		color: $anthracite-c;
		text-transform: uppercase;
		padding: 15px 100px;
		border: 2px solid $anthracite-c;
		position: relative;
		transform: translateZ(0);
		transition: color 1000ms;
		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $anthracite-c;
			transform: scaleX(0);
			transform-origin: 0 50%;
			transition-property: transform;
			transition: 300ms ease-out;
		}
		&:hover{
			color: white;
			&:before{
				transform: scaleX(1);
			}
		}
	}
}

// Events
.pg_events{
	.event{
		.day{
			hr{
				width: 20px;
				border-color: white;
			}
		}
		.mappa{
			.mappaimg{
				width: 100%;
			}
		}
	}
}

// Guide&Maintenance
.pg_guiman{
	background: url("../img/bg-mantenance.png") no-repeat;
	background-size: 55% auto;
	.welcome_main{
		ul{
			li{
				margin-bottom: 15px;
				padding-bottom: 15px;
				border-bottom: 1px solid #666;
			}
		}
	}
}
.cascata{
	.acc_heading{
		margin-bottom: 1px;
		.numbered{
			background-color: #3f454e;
		}
		.thitle{
			background-color: #33383f;
		}
	}
	.plus{
		rect{
			transition: all 800ms;
			transform: scaleY(1);
			transform-origin: 50% 50%;
		}
		&.minus{
			.vertical-line{
				transform: scaleY(0);
			}
		}
	}
	.wrap_accordion_l2{
		width: 100%;
		.panel{
			background-color: #292d32;
			margin-bottom: 1px;
		}
	}
}

// Web TV
.pg_webtv{
	background: url("../img/bg-mantenance.png") no-repeat;
	background-size: 55% auto;
	.webtvHead{
		li{
			display: inline-block;
			margin-right: 20px;
			&:last-of-type{
				margin-right: 0;
			}
			a{
				opacity: 0.5;
				&.sel{
					opacity: 1;
				}
			}
		}
	}
	.video_webtv{
		background-color: transparent;
		@media (min-width: 992px){
			margin-top: -50px;
		}
	}
	.webtv_list{
		a{
			.text_webtv{
				svg{
					min-width: 20px;
				}
			}
		}
	}
	.sfuma_vid{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100px;
		z-index: 9999;
		background-image: linear-gradient(to left, #11171f 25%, rgba(17, 23, 31, 0));
	}
}

// Team
.pg_team{
	background: url("../img/bg-mantenance.png") no-repeat;
	background-size: 55% auto;
    .webtvHead{
        li{
            display: inline-block;
            margin-right: 20px;
            &:last-of-type{
                margin-right: 0;
            }
            a{
                opacity: 0.5;
                &.sel{
                    opacity: 1;
                }
            }
        }
    }
    .video_webtv{
        background-color: transparent;
        @media (min-width: 992px){
            margin-top: -50px;
        }
    }
    .webtv_list{
        a{
            .text_webtv{
                svg{
                    min-width: 20px;
                }
            }
        }
    }
    .sfuma_vid{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100px;
        z-index: 9999;
        background-image: linear-gradient(to left, #11171f 25%, rgba(17, 23, 31, 0));
    }
	.expAtl{

	}
}
// Atleta
.pg_atleta{
	.headAtl{
		.text-atl{
			p{
				text-align: left !important;
				a{
					color: $green-c;
				}
			}
		}
		@media (min-width: 768px){
			&:after{
				content: '';
				display: table;
				clear: both;
			}
			.imgemoright{
				float: left;
				width: 40%;
			}
			.text-atl{
				float: right;
				width: 59%;
			}
		}
	}
}

/* dettaglio video */
.tv_dett, .tvpg_dett{
	.webtv-list{
		margin-left: -5px;
		margin-right: -5px;
		.webtv_video{
			padding: 0 5px;
			margin-bottom: 10px;
		}
		a{
			overflow: hidden;
		}
	}
}
/* dettaglio cat video*/
.tvpg_dett{
	background: url(../img/bg-video-category.jpg) no-repeat;
	background-size: 55% auto;
	.webtvHead{
		li{
			display: inline-block;
			margin-right: 20px;
			a{
				opacity: 0.5;
				&.sel{
					opacity: 1;
				}
			}
		}
	}
}

.myescape {
	background-image: url('../img/2021/top-cat-bg.jpg');
	&_top {
		background-image: url("../img/myescape/emotional.jpg");
		background-size: cover;
		background-position: center center;
		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
			background-image: url("../img/myescape/emotional_2x.jpg");
		}
	}
	&_wrap {
		padding: 70px 0;
	}
	blockquote {
		font-size: 18px;
		font-family: 'ManifoldCF-RegularOblique', sans-serif;
		footer {
			text-align: center;
			margin-top: 1rem;
			font-family: 'ManifoldCF-DemiBoldOblique', sans-serif;
		}
	}
}
.molink {
	overflow: hidden;
	img {
		transition: all .3s ease-in-out;
	}
	&:hover {
		img {
			transform: scale(1.05);
		}
		svg {
			fill:#c3d52e;
		}
	}
}
.btn-roller {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 12px;
	color: #323E47;
	border-radius: 0;
}
.titoletto {
	font-family: 'ManifoldCF-ExtraBoldOblique', sans-serif;
	font-size: 40px;
	line-height: 1;
	text-transform: uppercase;
}
.titolettoh3 {
	@extend .titoletto;
	font-size: 30px;
}
.titolettoh4 {
	@extend .titoletto;
	font-size: 20px;
}
.alpha {
	opacity: .5;
}
.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	content: "";
}
.question {
	.panel-collapse a {
		color: $green-u;
		text-decoration: underline;
	}
}

.road-to-berlin {
    &__cta {
        background-color: #c3d52e;
        display: block;
        padding: 15px;
        font-family: ManifoldCF-BoldOblique,sans-serif;
    }
}
