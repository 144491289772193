.e2mblade {
  margin-top: 80px;
  &__title {
    font-size: 65px;
    letter-spacing: 0;
    line-height: 60px;
    margin: 0;
  }
  &__subtitle {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 28px;
    color: $anthracite-s;
    margin: 15px 0;
    sub{
      display: block;
      font-family: 'ManifoldCF-DemiBold',sans-serif;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 24px;
      font-weight: bold;
      bottom: initial;
    }
  }

  &__intro {
    color: $anthracite-s;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 21px;
  }
  &__top {
    position: relative;
    padding: 0;
    > img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        position: relative;
      }
    }
    &-content {
      position: relative;
      padding: 60px 15px;
      @include media-breakpoint-up(lg) {
        padding: 0 15px;
      }
    }
    &-wrap {
      margin: 0;
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-cover {
      background: $green-c;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      mix-blend-mode: color;
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 0% 1000%);
      @include media-breakpoint-up(lg) {
      clip-path: polygon(0% 0%,100% 0%,90% 100%,0% 1000%);
      }

    }
  }
  &__block {
    margin: 60px 0 0 0;
    padding-bottom: 60px;
    border-bottom: 1px solid #D8D8D8;
    &-pre {
      font-family: 'ManifoldCF-Bold';
      font-size: 15px;
      color: $green-c;
      letter-spacing: 0;
      line-height: 21px;
    }
    &-title {
      font-family: 'ManifoldCF-ExtraBoldOblique';
      font-size: 28px;
      color: $anthracite-s;
      letter-spacing: 0;
      line-height: 28px;
      margin: 0 0 10px 0;
      sub {
        display: block;
        font-family: 'ManifoldCF-DemiBold';
        font-size: 18px;
        color: $anthracite-s;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        bottom: initial;
      }
    }
    &-text {
      margin: 15px 0 0 0;
    }
    &-image {
      margin: 30px 0 0 0;
      @include media-breakpoint-up(lg) {
        margin: 0;
      }
      border-radius: 10px;
    }
  }
  &__products {
    &-top {
      margin: 60px 0 0 0;
    }
    &-title {
      font-family: 'ManifoldCF-ExtraBoldOblique';
      color: $anthracite-s;
      font-size: 28px;
      letter-spacing: 0;
      line-height: 28px;
    }
  }
  &__product {
    display: inline-block;
    position: relative;
    margin: 45px 0 0 0;
    &-alert {

    }
    &-title {
      font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
      font-size: 16px;
      color: #242424;
      letter-spacing: 0;
      line-height: 17px;
      margin: 0 0 5px 0;
      position: relative;
      display: inline-block;
      &:before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        overflow: hidden;
        width: 0;
        border-bottom: 2px solid $green-c;
        transition: width 0.5s ease;
        z-index: -1;
        transform: skewX(-30deg);
      }
    }
    &-text {
      font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
      font-size: 13px;
      color: #989EA3;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0 0 10px 0;
    }
    &-price {
      font-family: 'ManifoldCF-DemiBold',sans-serif;
      font-size: 13px;
      color: $anthracite-c;
      letter-spacing: 0;
      line-height: 16px;
    }
    &:hover {
      .e2mblade__product-title {
        &:before {
          width: 100%;
        }
      }
    }
  }
}