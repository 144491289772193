.slideContRel{
	&__sizer {
		min-height: 70vh;
		&D {
			min-height: 640px;
		}
	}
	height: auto;
	overflow: hidden;
	margin-top: 75px;
	@media (min-width: 768px){
		margin-top: 0;
	}
	.slideshow{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 888;
		height: 100%;
	}

    .recall_usa &{
        margin-top: 95px;
        @media (min-width: 768px){
            margin-top: 0;
        }
    }
}
.slideshow, .slideContRel{
	position: relative;
	width: 100%;
}

/* sfumatura slide */
.slideshow:before{
	position: absolute;
	content: '';
	background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%);
	width: 100%;
	height: 23%;
	z-index: 8009;
	pointer-events: none;
}

.slideshow::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.4);
	z-index: 1000;
	opacity: 0;
	transition: opacity 0.4s;
	pointer-events: none;
}

.slideshow--details::after {
	opacity: 1;
}

.slide {
	width: 100%;
	height: 70vh;
	top: 0;
	left: 0;
	position: absolute;
	overflow-x: hidden;
	pointer-events: none;
	opacity: 0;
}

.slide--current {
	opacity: 1;
	z-index: 1000 !important;
	pointer-events: auto;
	position: relative;
}

.slide__wrap {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
}

.slide__bg {
	background-size: contain;
}

.slide__bg,
.slide__img {
	width: 100%;
	height: 50%;
	left: 0;
	top: 0;
	background-color: white;
	background-size: cover;
	position: absolute;
	pointer-events: none;
	@include media-breakpoint-up(md) {
		height: 100%;
	}
}

.slide__bg {
	filter: grayscale(1) brightness(0.7);
}

.slide__title-wrap {
	position: absolute;
	height: 50%;
	width: 100%;
	top: 50%;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 16px;
	background: #FFF;
	@include media-breakpoint-up(md) {
		width: auto;
		background: transparent;
		height: 100%;
		top: 0;
	}
	&--1 {
		background: #e7e9e3;
	}
}

.slide__cta {
	&-wrap {
		display: flex;
		margin: 16px 0 0 0;
		@include media-breakpoint-up(md) {
			margin: 32px 0 0 0;
		}
	}
	font-family: $fontf--extraboldob;
	font-size: 15px;
	color: $anthracite-s;
	line-height: 1;
	padding: 12px 18px;
	background: $green-c;
	&--1 {
		margin: 0 0 0 16px;
	}
	&--buy {
		display: flex;
		align-items: center;
		line-height: 1.25;
		img {
			width: 20px;
			height: auto;
			margin: 0 8px 0 0;
		}
	}
	&:hover {
		color: $anthracite-s;
		transform: scale(1.05);
	}
	@media(min-width: 768px) {
		padding: 16px 48px;
	}
}

.slide__logo-img {
	margin: 0 0 16px 0;
	@include media-breakpoint-up(md) {
		margin: 0 0 32px 0;
	}
}

.slide__title {
	font-family: $fontf--extraboldob;
	color: $anthracite-s;
	letter-spacing: 0;
	margin: 0 0 0 0;
	font-size: 32px;
	line-height: 32px;
	@include media-breakpoint-up(md) {
		color: #FFFFFF;
		font-size: 64px;
		line-height: 60px;
	}
	&--black {
		color: $anthracite-s;
	}
}

.slide__subtitle {
	font-family: $fontf--boldob;
	font-size: 16px;
	color: #989EA3;
	letter-spacing: 0;
	line-height: 1;
	margin: 16px 0 0 0;
	@include media-breakpoint-up(md) {
		font-size: 28px;
	}
	&--black {
		color: #323E47;
	}
}

.slide__text {
	margin: 32px 0 0 0;
	font-size: 16px;
	color: #989EA3;
	letter-spacing: 0;
	line-height: 20px;
	font-family: $fontf--demibold;
	strong {
		font-family: $fontf--extrabol;
	}
	&--black {
		color: $anthracite-s;
	}
}

.boxnav {
	display: flex;
	z-index: 1000;
	width: 100%;
	background-color: $anthracite-s;
	position: relative;
}

.boxnav__item {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 4rem;
	flex: 1;
	border: none;
	background: transparent;
	svg path{
		transition: all 0.35s ease-in-out;
	}
}

.boxnav__item--prev:hover,
.boxnav__item--next:hover {
	cursor: pointer;
	svg path{
		fill: $green-c;
	}
}

.boxnav__item:focus {
	outline: none;
}

.boxnav__label {
	padding: 0 0.5rem;
	font-size: 14px;
	letter-spacing: 0.6px;
	position: relative;
	color: white;
}

.boxnav__label--total::before {
	content: '';
	position: absolute;
	right: 100%;
	top: 0;
	width: 1px;
	height: 1.45rem;
	background: white;
	transform: rotate(22.5deg);
}

.details-wrap {
	position: relative;
	pointer-events: none;
	width: 100%;
	z-index: 10000;
}

@media screen and (min-width: 768px) {
	.slide {
		min-height: 100%;
	}
	.slide__title-wrap {
        padding: 0 0 0 4rem;
        text-align: left;

        sup {
            top: -1em;
            font-size: .4em;
        }
    }
	.slide__title-wrap--1 {
		padding: 0 4rem;
	}
	.slide__wrap {
		height: 100%;
		position: absolute;
	}
	.slide__bg,
	.slide__img {
		background-position: 50% 0%;
	}
	.boxnav {
		position: absolute;
		bottom: 0;
		left: 0;
		width: auto;
	}
	.boxnav__item {
		width: 5vw;
		height: 5vw;
		flex: none;
	}
}
