.pgCategoria {
  &__nav {
    &Sep {
      margin: 0 4px;
    }
  }
  &__top {
    margin: mul(9) 0 0 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__title {
    font-size: 65px;
    color: $anthracite-c;
    letter-spacing: 0;
    line-height: 60px;
    margin: 4px 0 32px 0;
    text-transform: uppercase;
    &.sts{
      font-size: 45px;
      line-height: 40px;
    }
  }
  &__deco {
    height: 80px;
    width: 32px;
    display: inline-block;
    align-self: center;
    &--1 {
      background-image: linear-gradient(180deg, rgba(182,198,44,0.10) 0%, #B6C62C 100%);
      margin-bottom: mul(8);
    }
    &--2 {
      background-image: linear-gradient(180deg, rgba(182,198,44,0.10) 0%, #B6C62C 100%);
      margin-top: mul(8);
    }
  }
  &__body {
    &Title {
      margin: mul(9) 0 0;
      text-align: center;
      &--2 {
        margin: mul(9) 0 mul(6);
      }
    }
    &Row {
      margin: mul(5) 0 0 0;
      &--1 {
        border-bottom: 1px solid $green-c;
      }
    }
    &CTA {
      font-family: $fontf--boldob;
      font-size: 14px;
      color: $anthracite-c;
      letter-spacing: 0;
      line-height: 1;
      padding: mul(2) mul(6);
      background: #E9E9E3;
      display: inline-block;
      margin: mul(8) 0;
      &:hover {
        color: $anthracite-c;
        transform: scale(1.05);
      }
    }
    &Col {
      &--1 {
        @include media-breakpoint-up(lg) {
          padding-right: 30px;
        }

      }
      &--2 {
        @include media-breakpoint-up(lg) {
          padding-left: 30px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;
            background: $green-c;
          }
        }

      }
    }
  }
  &__relatedCarousel {
    margin: mul(8) 0 mul(10) 0;
    position: relative;
    &-slide {
      @include media-breakpoint-up(lg) {
        padding: 0 0 0 32px;
      }
      &Wrap {
        position: relative;
        display: flex;
        overflow: hidden;
        img {
          transition: all .3s ease-in-out;
          height: 260px;
          object-fit: cover;
          //fix
        }
        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
      &Content {
        position: absolute;
        background: linear-gradient(to bottom, transparent, rgba(9,10,11,0.65));
        bottom: 0;
        left: 0;
        right: 0;
        padding: 16px;
        h3 {
          color:#FFF;
          font-size: 18px;
          line-height: 1;
          margin: 0 0 8px 0;
        }
        h4 {
          font-size: 12px;
          color: $green-c;
          letter-spacing: 0;
          line-height: 1;
          span {
            color: #999999;
          }
        }
      }
    }
    .slick-dots {
      display: flex;
      position: absolute;
      top: calc(100% + 40px);
      left: 50%;
      transform: translate3d(-50%,0,0);

      li {
        margin-left: 10px;
        &:first-of-type {
          margin-left: 0px;
        }
        button {
          width: 20px;
          height: 2px;
          text-indent: -9999px;
          display: inline-block;
          background: #CCC;
          border: none;
          outline: none;
        }
        &.slick-active {
          button {
            background: $green-c;
          }
        }
      }
    }
  }
}