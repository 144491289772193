//ONLINE STORE lista_commerce

.covid-19{
	text-align: center;
	background: #333;
	color: #C3D52E;
	padding: 2% 10%;
	p{
		margin: 0;
	}
}

.mob-140{
	width: 140% !important;
	@media (min-width: 768px){
		width: 100% !important;
	}
}
.hd_tit{
	overflow: hidden;
}
.box_onst{
	margin-bottom: 300px;
	@media (min-width: 992px){
		margin-bottom: 350px;
	}
	.sottocat-outlet{
		text-align: center;
		margin-bottom: 50px;
		@media (min-width: 768px){
			position: absolute;
			left: 0;
			right: 0;
			width: 100%;
			top: 95%;
			margin-bottom: 0;
		}
	}
}

//Dett commerce
.prodDetailBox.storecom{
	.storepricebtn{
		.prices{
			p{
				margin: 0;
			}
		}
	}
}

.logos-store{
	&--1 {
		border-bottom: 1px solid rgba(0,0,0,0.125);
	}
	padding-bottom: 15px;
	img{
		width: 100%;
	}
}

.logos-header {
	background: none;
	margin-bottom: 15px;
}

/* cart */
.checkoutUsa{
	.cart_head{
		.table{
			margin: 0;
			tbody{
				border-bottom: 1px solid #ccc;
				tr{
					border-top: 1px solid #ccc;
				}
			}
			td{
				vertical-align: middle;
				border: none;
				h4, p{
					margin: 0;
				}
			}
			th{
				border: none;
			}
			.campo_qty{
				width: 50px;
				text-align: center;
				vertical-align: middle;
			}
			.btn_up{
				background-color: $anthracite-c;
				opacity: 0.6;
				color: #fff;
				padding: 7px 20px;
				transition: all 0.5s ease-in-out;
				&:hover{
					opacity: 1;
				}
			}
			.btn_canc{
				cursor: pointer;
				&:hover{
					svg{
						path{
							fill: red;
						}
					}
				}
			}
		}
		.art_det{
			.avail{
				color: $green-s;
				font-size: 13px;
				font-family: 'ManifoldCF-RegularOblique';
				text-transform: uppercase;
				margin: 0;
				span{
					display: inline-block;
					width: 8px;
					height: 8px;
					background-color: $green-s;
					border-radius: 50px;
					margin-right: 3px;
				}
			}
			.notavail{
				color: red;
				font-size: 13px;
				font-family: 'ManifoldCF-RegularOblique';
				text-transform: uppercase;
				margin: 0;
				span{
					display: inline-block;
					width: 8px;
					height: 8px;
					background-color: red;
					border-radius: 50px;
					margin-right: 3px;
				}
			}
		}
	}
	.riepbox{
		.button-label-big{
			padding: 10px 30px;
			background-color: $green-s;
			display: inline-block;
			transition: all 0.5s ease-in-out;
			&:hover{
				background-color: white;
				color: $green-s;
			}
		}
	}
	.boxForm{
		.button-label-big{
			padding: 10px 40px;
			background-color: $green-s;
			border: 1px solid $green-s;
			color: white;
			transition: all 0.5s ease-in-out;
			&:hover{
				background-color: white;
				color: $green-s;
			}
		}
	}
}


.shopify-cart-layout {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    z-index:900;
    background-color: rgba(0,0,0,.50);
}

.shopify-cart-loader {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    z-index:999;
    border: 16px solid white;
    border-top: 16px solid #B6C62C;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.old_total_price {
    text-decoration: line-through;
    color: #828282;
}


