.born_bann{
	background-color: #11171F;
	position: relative;
	@media (min-width: 992px){
		padding-bottom: 50px;
	}
	.bornImage{
		//margin-top: 50px;
		@media (min-width: 768px){
			margin-top: 0px;
		}
	}
	hr.dots{
		border-top: 1px dashed #fff;
		margin: 30px 0;
	}
	.fourtxt{
		strong{
			font-family: 'ManifoldCF-ExtraBoldOblique';
			text-transform: uppercase;
			font-size: 20px;
			line-height: 40px;
		}
	}
	@media (min-width: 768px){
		.sfuma-top{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 250px;
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
		}
	}
	.born_txt{
		@media (min-width: 992px){
			position: absolute;
			top: 30%;
			left: 0;
			bottom: 0;
			right: 0;
			background-image: linear-gradient(to bottom, rgba(17, 23, 31, 0), rgba(17, 23, 31, 1) 100%, $anthracite-s);
		}
		.white{
			color: white;
		}
	}
}
.bkg_time{
	p{
		@media (min-width: 768px){
			width: 300px;
		}
		@media (min-width: 1280px){
			width: 500px;
		}
	}
}
.time_line{
	position: relative;
	p{
		margin-bottom: 0;
	}
	.owl-nav{
		button{
			position: absolute;
			top: 50%;
			margin-top: -15px;
			&.owl-prev{
				left: 0;
			}
			&.owl-next{
				right: 0;
			}
		}
	}
}
.distributor{
	position: relative;
	background-color: $anthracite-s;
	@media (min-width: 768px){
		background-color: transparent;
	}
	.list_dist{
		@media (min-width: 768px){
			position: absolute;
			.countryabout{
				.w-85{
					width: 85%;
				}
			}
		}
	}
}
.better{
	h1{
		font-size: 12px !important;
		@media (min-width: 768px){
			width: 50%;
		}
		@media (min-width: 992px){
			width: 40%;
			font-size: 24px !important;
		}
	}
}