.zetra-blade, .micro-blade {
    .intro-text {
        font-size: 1.2rem;
        @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
        }
    }

    .prod-name {
        font-size: 1.3rem;
        @include media-breakpoint-up(xl) {
            font-size: 2rem;
        }
    }

    .prod-code {
        font-size: .9rem;
        @include media-breakpoint-up(xl) {
            font-size: 1.06rem;
        }
    }

    .prod-color {
        color: $gray-extra;
        font-size: 1rem;
        @include media-breakpoint-up(xl) {
            font-size: 1.3rem;
        }
        font-family: "ManifoldCF-BoldOblique", sans-serif;
    }

    .btn-buy {
        font-size: $font-size-base;
        background-color: #c3d52e;
        border: 1px solid #c3d52e;
        @include media-breakpoint-up(lg) {
            font-size: 1.333rem;
        }
        line-height: 1.9;
    }

    .section-features {
        background: $gray-extra-2;

        .title {
            line-height: 0.96;
            font-size: 2.5rem;
            @include media-breakpoint-up(xl) {
                font-size: 3.46rem;
            }
        }

        .card-title {
            font-size: 1.5rem;
            @include media-breakpoint-up(lg) {
                font-size: 1.8rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 2.666rem;
            }
            color: $black;
        }

        .card-text {
            font-size: 1.1rem;
            line-height: normal;
            @include media-breakpoint-up(xl) {
                font-size: 1.3rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 2rem;
            }
            font-weight: 600;
            color: $gray-extra;
        }
    }
}
