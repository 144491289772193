/*!
Theme Name: Rollerblade
Author: Mapo Studio
*/

/* pop-up hp */
.popup_nl{
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 9999;
	max-width: 400px;
	.txt-sub{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		.span-company-infos{
			color: white;
		}
		.button-label{
			border: 1px solid $green-c;
			padding: 8px 24px;
			color: white;
		}
		.closepop{
			position: absolute;
			top: 20px;
			right: 20px;
			color: $green-c;
			cursor: pointer;
			font-size: 20px;
		}
	}
}

/* sfumatura slide */
.slideshow:before{
	position: absolute;
	content: '';
	background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%);
	width: 100%;
	height: 23%;
	z-index: 8009;
	pointer-events: none;
}

/* slide info */
.carosel_info{
	border-bottom: 1px solid #d8d8d8;
	.banslide_info{
		text-align: center;
		position: relative;
		.item{
			display: flex;
			align-items: center;
			justify-content: center;
			p{
				margin: 0;
			}
			img{
				max-height: 35px;
				width: auto;
			}
			a{
				border: 1px solid #979797;
				padding: 8px 15px;
				&:hover{
					background-color: #979797;
					color: white;
				}
			}
		}
		.owl-nav{
			button{
				background: transparent;
				border: none;
				padding: 0;
				line-height: 0;
				position: absolute;
				top: 50%;
				margin-top: -8.5px;
				outline: none !important;
			}
			.owl-prev{
				left: 15px;
				@media (min-width: 1400px){
					left: 60px;
				}
			}
			.owl-next{
				right: 15px;
				@media (min-width: 1400px){
					right: 60px;
				}
			}
			@media (max-width: 767px){
				display: none;
			}
		}
		.owl-dots{
			text-align: center;
			button{
				outline: none !important;
				span{
					display: inline-block;
					height: 3px;
					width: 20px;
					background-color: $bkg-gray-m;
					margin: 0 5px;
				}
				&.active{
					span{
						background-color: $green-c;
					}
				}
			}
			@media (min-width: 768px){
				display: none;
			}
		}
	}
}
.banner_hp{
	a{
		position: relative;
		display: block;
		overflow: hidden;
		&::before{
			position: absolute;
			top: 0;
			left: -75%;
			z-index: 9999;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.05) 100%);
			transform: skewX(-25deg);
		}
		img{
			transition: $transition;
		}
		&:hover{
			&::before{
				animation: shine .75s;
			}
			img{
				opacity: 0.95;
			}
		}
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}
/* netflix */
.box_netflix{
	overflow: hidden;
	padding: 32px 16px;
	background-image: linear-gradient(to bottom, $anthracite-c, $anthracite-s);
	text-align: center;
	.slick-dots {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		button {
			text-indent: -9999px;
			display: inline-block;
			overflow: hidden;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: #FFF;
			border: none;
			padding: 0;
            &::before {
                content: none;
            }
		}
		li {
			margin-left: 10px;
			&:first-child {
				margin-left: 0px;
			}
			&.slick-active {
				button {
					background: $green-c;
				}
			}
		}
	}
	.owl_tech {
		display: inline-block;
		width: 100%;
		.boxcol {
			padding: 5px;
			position: relative;
		}
		.intbx {
			position: absolute;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(9, 10, 11, 0.65));
			bottom: 5px;
			left: 5px;
			right: 5px;
			padding: 0 0 30px 0;
			p{
				font-family: 'ManifoldCF-RegularOblique';
				margin: 0;
			}
		}
	}
	&__title {
		font-family: $fontf--boldob;
		color: #FFF;
		text-align: center;
		margin: 0 0 32px 0;
		font-weight: 400;
		font-size: 28px;
	}
	@media (min-width: 768px) {
		padding: 32px 72px 64px;
	}
	@media (min-width: 992px){

	}

	&.spostato{
		padding-left: 0;
		.owl_net .owl-nav .owl-prev{
			left: 0;
		}
	}
	.boxcol {
		&__title {
			font-size: 30px;
			margin: 0 0 24px 0;
		}
		&__cta {
			&-wrap {
				display: flex;
				justify-content: center;
			}
			text-transform: uppercase;
			font-family: $fontf--boldob;
			font-size: 13px;
			line-height: 1;
			color: $green-c;
			padding: 8px 32px;
			border: 1px solid $green-c;
			&:last-child {
				margin-left: 16px;
			}
			&:hover {
				background: $green-c;
				color: #FFF;
			}
		}
	}
}
.owl_net{
	position: relative;
	.owl-nav{
		button{
			position: absolute;
			top: -60px;
			bottom: -60px;
			background-color: transparent;
			outline: none !important;
			img{
				padding: 0 25px 0 10px;
			}
		}
		.owl-prev{
			left: -60px;
			background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.7)) !important;
		}
		.owl-next{
			right: 0;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.7)) !important;
		}
	}
	.owl-item{
		z-index: 10;
		transition: all 0.3s ease-in-out;
		opacity: 0.8;
		&:hover{
			z-index: 999;
			opacity: 1;
			@media (min-width: 768px){
				transform: scale(1.075);
				transition-delay: 0.15s;
			}
		}
		.boxcol{
			position: relative;
			display: block;
			z-index: 10;
			.intbx{
				position: absolute;
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(9, 10, 11, 0.65));
				bottom: 0;
				left: 0;
				right: 0;
				padding: 0 0 32px 0;
				p{
					font-family: 'ManifoldCF-RegularOblique';
					margin: 0;
				}
			}
		}
	}
	.owl-stage-outer{
		overflow: visible;
	}
}
.themachinethatgoesping {
	isolation: auto;
	display: none;
}
/* experience */
.block_exp{
	.tit_blocexp{
		@media (min-width: 992px) {
			text-align: center;
		}
	}
	.min_list_exp{
		.item_exp{
			a{
				display: flex;
				align-items: center;
				padding-top: 15px;
				padding-bottom: 15px;
				border-top: 1px solid #ddd;
				@media (min-width: 768px){
					display: block;
					position: relative;
					overflow: hidden;
					padding: 0;
					border: none;
				}
				img{
					width: 40%;
					margin-right: 15px;
					@media (min-width: 768px){
						width: 100%;
						margin-right: 0;
					}
				}
				.text_exp{
					@media (min-width: 768px){
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						background-image: linear-gradient(to bottom, rgba(17, 23, 31, 0), rgba(17, 23, 31, 0.8));
					}
					.experience-list-item-title{
						color: $anthracite-s;
						@media (min-width: 768px){
							color: white;
						}
					}
				}
				&::before{
					position: absolute;
					top: 0;
					left: -75%;
					z-index: 9999;
					display: block;
					content: '';
					width: 50%;
					height: 100%;
					background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.05) 100%);
					transform: skewX(-25deg);
				}
				img{
					transition: $transition;
				}
				&:hover{
					&::before{
						animation: shine .75s;
					}
					img{
						opacity: 0.95;
					}
				}
			}
			&:nth-of-type(2n){
				@media (min-width: 992px){

				}
			}
		}
	}
}
.btn_mob{
	a{
		background-color: $green-c;
		padding: 10px;
		display: block;
		margin-bottom: 30px;
	}
}
.item_new{
	a{
		.imgBox{
			overflow: hidden;
			margin-bottom: 15px;
			@media (min-width: 768px){
				margin-bottom: 0;
				min-width: 200px;
			}
			img{
				transition: all 0.5s ease-in-out;
				width: 50%;
			}
		}
		.experience-list-item-title{
			position: relative;
			display: inline-block;
			&::before{
				content: '';
				position: absolute;
				bottom: -3px;
				left: 0;
				overflow: hidden;
				width: 0;
				border-bottom: 2px solid $green-c;
				transition: width 0.5s ease;
				transform: skewX(-30deg);
			}
		}
		&:hover{
			.imgBox{
				img{
					transform: scale(1.03);
				}
			}
			.experience-list-item-title{
				&::before {
					width: 100%;
				}
			}
		}
	}
}

/* web tv video */
.video_webtv{
	background-color: #f0f0f0;
	#video{
		width: 100%;
		background-color: transparent;
		position: relative;
		@media (max-width: 600px){
			max-height: 250px;
		}
		.vjs-icon-play::before{
			content: '';
			background: url("../img/ico-player.svg") no-repeat;
		}
		.vjs-big-play-button{
			border: none;
			background: none;
			width: 50px;
			height: 50px;
			margin-top: -25px;
			margin-left: -25px;
			.vjs-icon-placeholder{
				&:before{
					content: '';
					background: url("../img/ico-player.svg") no-repeat;
					width: 50px;
					height: 50px;
				}
			}
		}
	}
	.minivideo{
		border-bottom: 1px solid #DCDCDC;
		padding-bottom: 37px;
	}
	#listvid.vjs-playlist{
		background-color: transparent;
		padding: 0 15px;
		margin-top: 15px;
		overflow-x: hidden;
		height: 102px;
		@media (min-width: 768px){
			height: 530px;
		}
	}
	.vjs-playlist{
		.vjs-playlist-item{
			&::before{
				content: '';
				background: url("../img/ico-player.svg") no-repeat;
				width: 50px;
				height: 50px;
				position: absolute;
				top: 50%;
				margin-top: -25px;
				left: 50%;
				margin-left: -25px;
				transition: all 0.35s;
			}
			width: 50%;
			float: left;
			background-color: #000;
			.vjs-playlist-thumbnail img{
				transition: all 0.5s ease-in-out;
			}
			.vjs-playlist-title-container{
				opacity: 0;
				transition: all 0.5s ease-in-out;
			}
			&:hover{
				&::before{
					content: none;
				}
				.vjs-playlist-thumbnail img{
					opacity: 0.5;
				}
				.vjs-playlist-title-container{
					opacity: 1;
				}
			}
			@media (min-width: 768px){
				width: 100%;
				float: none;
			}
		}
	}
}

/* prod in evidenza */
.prod_evi{
	.prd_evi{
		border: 3px solid #f0f0f0;
		a{
			position: relative;
			text-align: center;
			border-left: 3px solid #f0f0f0;
			padding: 35px 15px;
			overflow: hidden;
			&:first-of-type{
				border: none;
			}
			.promo_ev{
				position: absolute;
				transform: rotate(-45deg);
				top: 18px;
				left: -22px;
				span{
					background-color: $green-c;
					text-align: center;
					font-family: 'ManifoldCF-ExtraBold';
					text-transform: uppercase;
					padding: 4px 24px;
					font-size: 9px;
				}
			}
			img{
				margin: 0 auto;
				transition: $transition;
				width: 60%;
			}
			div{
				.price_prodev{
					.old_price{
						text-decoration: line-through;
						font-size: 16px;
						color: #828282;
						margin-right: 5px;
					}
				}
			}
			&:hover{
				img{
					transform: scale(1.05);
				}
				.skate-list-name{
					color: $green-c;
				}
			}
			.label{
				position: absolute;
				top: 20px;
				right: 20px;
				img{
					max-height: 30px;
					margin-bottom: 10px;
					width: auto;
					max-width: 72px;
					float: right;
					clear: both;
				}
				.lb_prem{
					display: none;
					height: 30px;
					max-height: 30px;
				}
			}
		}
	}
}

//404
.notfound{
	p{
		color: #fff;
		font-family: 'ManifoldCF-ExtraBold';
		margin: 0;
		text-transform: uppercase;
		font-size: 25px;
		&.big{
			font-size: 120px;
		}
	}
}

.fancybox-is-open .fancybox-bg{
	background-color: #eee;
	opacity: 0.9;
}

.confirm_reg{
	margin-top: 50px;
	position: relative;
	min-height: 250px;
	svg{
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -125px;
		z-index: 100;
	}
	h1{
		z-index: 300;
	}
}

@media (min-width: 768px) {
	.notfound .d-flex{
		p{
			font-size: 30px;
			strong{
				font-size: 200px;
			}
		}
	}
}

.highlightProducts {
	overflow: hidden;
	&__carousel {
		position: relative;
		transition: all .5s;
	}

	&__slide {
		position: relative;
		.price {
			display: block;

		}
		.text-left {
			&:before {
				width: calc(100% - 16px);
				height: 1px;
				background: #D8D8D8;
				margin-bottom: 16px;
				display: inline-block;
				content: '';
			}
			&--2 {
				border-top: 1px solid #D8D8D8;
				padding-top: 16px;
			}
		}
		&-thumb {
			padding: 16px;
			overflow: hidden;
			img {
				transition: all .3s ease-in-out;
			}
		}
		&:hover {
			.highlightProducts__slide-thumb {
				img {
					transform: scale(1.05);
				}
			}
		}
	}
	margin: mul(9) 0 mul(14) 0;
	.nav-tabs {
		border: none;
		margin-bottom: 40px;
	}

	.nav-link {
		font-family: $fontf--boldob;
		font-size: 20px;
		color: #989EA3;
		letter-spacing: 0;
		text-transform: uppercase;
		border: none;
		padding: 8px 16px;
		transition: all .5s;
        cursor: pointer;
		@media (max-width: 768px) {
			font-size: 15px;
			padding: 8px;
		}
		&.active {
			position: relative;
			color: $anthracite-s;
			&:after {
				content: '';
				position: absolute;
				bottom: 4px;
				left: 16px;
				overflow: hidden;
				width: calc(100% - 32px);
				border-bottom: 4px solid $green-c;
				transition: width 0.5s ease;
				transform: skewX(-30deg);
			}
		}
		&:hover {
			color: $anthracite-s;
		}
	}
	.slick-list {
		@media (max-width: 768px) {
			padding: 0 20% 0 0;
		}
	}
	.slick-dots {
		display: flex;
		position: absolute;
		top: calc(100% + 40px);
		left: 50%;
		transform: translate3d(-50%,0,0);

		li {
			margin-left: 10px;
			&:first-of-type {
				margin-left: 0px;
			}
			button {
				width: 20px;
				height: 2px;
				text-indent: -9999px;
				display: inline-block;
				background: #CCC;
				border: none;
				outline: none;
			}
			&.slick-active {
				button {
					background: $green-c;
				}
			}
		}
	}
}

.mte {
	padding: 64px 0 0;
	&__title {
		font-family: $fontf--boldob;
		color: $anthracite-s;
		text-align: center;
		margin: 0 0 32px 0;
		font-weight: 400;
		font-size: 28px;
		text-transform: uppercase;
	}
	&__col {
		&--1 {
			@include media-breakpoint-up(lg) {
				padding-right: 10px;
			}
		}
		&--2 {
			margin-top: 21px;
			@include media-breakpoint-up(lg) {
				margin-top: 0;
				padding-left: 10px;
			}
		}
	}
	&__box {
		display: flex;
		align-items: flex-start;
		overflow: hidden;
		position: relative;
		img {
			transition: all 0.3s ease-in-out;
		}
		&:hover {
			img {
				transform: scale(1.05);
				transition-delay: 0.15s;
			}

		}
		&--last {
			margin-top: 21px;
		}
		&-content {
			position: absolute;
			background: linear-gradient(to bottom, transparent, rgba(9,10,11,0.65));
			bottom: 0;
			left: 0;
			right: 0;
			padding: 32px;
			h4 {
				font-size: 28px;
				color: #FFFFFF;
				margin: 0 0 16px;
				text-transform: uppercase;
			}
			span {
				font-family: $fontf--boldob;
				font-size: 13px;
				color: $anthracite-s;
				line-height: 14px;
				background: $green-c;
				padding: 8px 32px;
			}
		}
	}
}

.hpEvents {
	padding-bottom: mul(7);
	&__title {
		margin: 4px 0 16px 0;
	}
	&__cta {
		font-family: $fontf--extraboldob;
		font-size: 16px;
		color: $anthracite-s;
		letter-spacing: 0;
		line-height: 1;
	}
	&__box {
		transition: all 0.3s ease-in-out;
		margin-top: mul(5);
		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}
		&:hover {
			transform: scale(1.05);
		}
		&Date {
			font-family: $fontf--boldob;
			font-size: 12px;
			color: $green-c;
			letter-spacing: 0;
			line-height: 1;
		}
		&Title {
			font-size: 18px;
			color: #11171F;
			line-height: 18px;
			margin: 4px 0 8px;
		}
	}
}
