.bann_tec{
	overflow: hidden;
	.w-100{
		@media (max-width: 767px){
			transform: scale(1.3);
			transform-origin: top right;
		}
	}
	.shape{
		display: none;
		@media (min-width: 768px){
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			.opacity{
				opacity: 0.4;
				height: 100%;
				width: auto;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
			}
			svg{
				height: 100%;
				width: auto;
			}
		}
	}
	.position-absolute{
		@media (max-width: 767px){
			position: relative !important;
			background-color: $green-c;
			padding: 20px;
		}
	}
}

//MAXXUM
.w-md-sm{
	@media (min-width: 768px){
		width: 50%;
	}
	@media (min-width: 1280px){
		width: 29%;
	}
}
.pg_max-edg{
	.mx-h{
		max-height: 250px;
	}
	hr{
		border-top: 1px solid #979797;
	}
}

/* discover prod list */
.discover_prod{
	a{
		position: relative;
		display: block;
		.img-fluid{
			max-height: 200px;
			display: block;
			transition: all 0.5s ease-in-out;
		}
		.badgelist{
			width: 30px;
			position: absolute;
			top: 20px;
			right: 20px;
		}
		.skate-list-name{
			position: relative;
			display: inline-block;
			&::before{
				content: '';
				position: absolute;
				bottom: -3px;
				left: 0;
				overflow: hidden;
				width: 0;
				border-bottom: 2px solid #B6C62C;
				transition: width 0.5s ease;
				z-index: -1;
				transform: skewX(-30deg);
			}
		}
		&:hover{
			img{
				transform: scale(1.1);
			}
			.skate-list-name{
				&::before{
					width: 100%;
				}
			}
		}
	}
}

//X-FIT
.xfit-desc1{
	background: url(../img/xfit-desc1-bg.jpg) no-repeat center;
	padding: 80px 0;
	@media (min-width: 768px){
		padding: 100px 0;
	}
	@media (min-width: 992px){
		padding: 150px 0;
	}
}
.xfit-desc2{
	.col-md-6{
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		p{
			width: 90%;
			font-size: 20px;
			line-height: 23px;
			margin: 0;
			padding: 30px 0;
			strong{
				font-family: 'ManifoldCF-Bold';
			}
		}
	}
	img{
		width: 100%;
	}
}

//3WD
.marathon_live{
	background: url(../img/marathon.png) no-repeat center;
	padding: 50px 0;
	a{
		.mara_txt{
			p{
				margin: 0;
			}
			img{
				margin-left: 15px;
				max-width: 40px;
			}
		}
	}
	.maracentrale{
		padding: 50px 0;
		border: 3px solid #fff;
		border-radius: 3px;
		p{
			strong{
				color: #7ED321;
			}
		}
	}
}
.upgrade_3wd{
	.wd_urban{
		border-right: 1px solid #ccc;
	}
	.text-left{
		padding: 30px;
		img{
			max-width: 50px;
		}
		table{
			th{
				font-family: 'ManifoldCF-Bold';
				padding-right: 10px;
			}
			td{
				font-size: 13px;
			}
		}
	}
}

//ENDURACE
.mar21{
	padding: 50px 0;
	margin: auto;
	h4{
		font-size: 22px;
		color: #999;
		text-transform: uppercase;
		margin: 0;
	}
	h3{
		font-size: 30px;
		text-transform: uppercase;
		margin-bottom: 30px;
	}
	p{
		font-size: 15px;
		margin: 0;
	}
}

//TWISTER
.boxtwi{
	h2{
		border-bottom: 1px solid #ccc;
	}
	h3{
		border-bottom: 1px solid #ccc;
		min-height: 150px;
		span{
			color: #999;
			display: contents;
		}
	}
	p{
		margin-bottom: 0;
	}
	.lineaz{
		border: 1px dashed $green-c;
		margin-top: 30px;
		&.mb30{
			margin-bottom: 30px;
		}
	}
	.fr-giu{
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 20px 15px 0 15px;
		border-color: $green-c transparent transparent transparent;
		margin: 6px auto;
	}
}