$multiplier : 8;
$fontf--extraboldob : 'ManifoldCF-ExtraBoldOblique';
$fontf--extrabol : 'ManifoldCF-ExtraBold';
$fontf--boldob : 'ManifoldCF-BoldOblique';
$fontf--bold : 'ManifoldCF-Bold';
$fontf--demibold: 'ManifoldCF-DemiBold';
$blue--1: #323e47;

/**
 * Calculate multiples of multiplier
 */
@function mul($base) {
  $result: #{$multiplier*$base}px;
  @return $result;
}

%highlight {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px;
    right: -5px;
    background-color: $green-c;
    transition: background-color 0.35s ease-in-out;
    transform: skewX(-20deg);
    z-index: -1;
  }
}

/* SALESFORCE CHAT */
.embeddedServiceHelpButton .helpButton .uiButton{
  background-color:#c3d52e !important;
  border-radius:0 !important;
  right: 0px !important;
  transition: all 0.4s ease-in-out;
  font-size: .8em !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:hover{
  background-color:#222 !important;
  border-radius:0 !important;
  color: #FFFFFF !important;
  .message {
    color: #FFFFFF !important;
  }
}
.embeddedServiceHelpButton .embeddedServiceIcon::before {
  font-family: "embeddedserviceiconfont" !important;
  color: #FFF;
}
.embeddedServiceHelpButton .helpButton .uiButton::before {
  display: none !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: none;
}

.embeddedServiceSidebarForm .embeddedServiceSidebarButton {
  background-color: #c3d52e !important;
  .label {
    color: #11171f;
  }
}

.sidebarHeader h2 {
  color: #FFF;
}

.embeddedServiceLiveAgentStateChatAvatar.isLightningOutContext .agentIconColor0 {
  background-color: #c3d52e !important;
  color: #11171f !important;
}
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
  background: #c3d52e !important;
  color: #11171f !important;
}

.embeddedServiceSidebarButton .label, .embeddedServiceSidebarButton:disabled .label {
  color: #11171f !important;
}
.embeddedServiceSidebarMinimizedDefaultUI .minimizedText > .message {
  color: #11171f !important;
}
.embeddedServiceLiveAgentStateChat .endChatContainer .endChatButton {
  background: #c3d52e !important;
}

.embeddedServiceSidebarButton.uiButton--inverse, .embeddedServiceSidebarButton.uiButton--inverse:disabled {
  color: #11171f !important;
}

.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus, .embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover, .embeddedServiceSidebarButton.uiButton--inverse, .embeddedServiceSidebarButton.uiButton--inverse:disabled, .embeddedServiceSidebarButton:not(:disabled):focus, .embeddedServiceSidebarButton:not(:disabled):hover, .embeddedServiceSidebarButton, .embeddedServiceSidebarMinimizedDefaultUI.helpButton {
  background: #c3d52e !important;
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton {
  border-radius:0 !important;
}
/* SALESFORCE CHAT  */

.cart-thumb {
    max-width: 120px;
    margin-right: 24px;
}
