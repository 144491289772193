.lightning-lp {
    .hr {
        background: #d8d8d8;
        @include media-breakpoint-up(lg) {
            background: #fff;
        }

        &-dark {
            background: #979797;
        }
    }

    .promo-text{
        font-size: 1.75rem;
    }

    .nav-top-space {
        margin-top: 75px;
        @include media-breakpoint-up(lg) {
            margin-top: 70px;
        }
    }

    .hero-img {
        @include media-breakpoint-up(lg) {
            min-height: calc(100vh - 70px);
            object-fit: cover;
        }
    }

    .hero-row {
        @include media-breakpoint-up(lg) {
            background-image: url(/assets/img/lightning-lp/bitmap@2x.webp);
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .hero-content {
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: calc(50% + 35px);
            left: 0;
            color: $white;
            padding: 0 0 0 4rem;
            transform: translateY(-50%);
            //margin-top: 70px;
        }

        .logo {
            margin-bottom: 24px;
            max-width: 100px;
            filter: invert(1);
            @include media-breakpoint-up(lg) {
                margin-bottom: 34px;
                max-width: 180px;
                filter: invert(0);
            }
        }

        .title {
            font-size: 28px;
            line-height: 0.92;
            @include media-breakpoint-up(lg) {
                color: $white;
                font-size: 64px;
                white-space: nowrap;
            }
        }

        .sub-title {
            font-size: 18px;
            text-transform: uppercase;
            font-family: ManifoldCF-Bold, sans-serif;
            line-height: 0.88;
            @include media-breakpoint-up(lg) {
                color: $white;
                font-size: 24px;
            }
        }
    }

    .intro-section {
        padding: 48px 0;
        @include media-breakpoint-up(lg) {
            padding: 94px 0 190px;
        }
        background-image: linear-gradient(144deg, #fff8cc 0%, #fff 48%, #fac6ea);

        .title {
            font-size: 32px;
            line-height: 0.96;
            text-align: center;
            margin-bottom: 48px;
            @include media-breakpoint-up(lg) {
                font-size: 52px;
                margin-bottom: 94px;
            }
        }

        .feature-section {
            margin-bottom: 40px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 160px;
            }

            .feature-img {
                max-width: 80%;
                margin-bottom: 35px;
                margin-right: auto;
                margin-left: auto;
            }

            .feature-text {
                font-size: 16px;
                line-height: 1.31;
                font-family: ManifoldCF-Bold, sans-serif;
                @include media-breakpoint-up(lg) {
                    padding: 0 8px;
                }

                span {
                    text-transform: uppercase;
                }
            }
        }
    }

    .product-img {
        width: 88%;
        margin-bottom: 40px;
        aspect-ratio: 1.419;
        object-fit: contain;
        @include media-breakpoint-up(lg) {
            margin-bottom: 55px;
        }
    }

    .product-img-2 {
        width: 90%;
        margin-bottom: 48px;
        aspect-ratio: 1.419;
        object-fit: contain;
        object-position: 16px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 55px;
        }
    }

    .product-name {
        font-size: 30px;
    }

    .product-text {
        line-height: 1.31;
        font-size: 16px;

        h5 {
            font-size: 16px;
            font-family: ManifoldCF-Bold, sans-serif;
        }
    }
}
