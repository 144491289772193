@charset "UTF-8";
@font-face {font-family: 'ManifoldCF-Regular';
/*src: url('/assets/fonts/ManifoldCF-Regular.eot');
src: url('/assets/fonts/ManifoldCF-Regular.eot#iefix') format('embedded-opentype'),*/src: url('/assets/fonts/ManifoldCF-Regular.woff2') format('woff2'),url('/assets/fonts/ManifoldCF-Regular.woff') format('woff'),url('/assets/fonts/ManifoldCF-Regular.ttf') format('truetype');}

@font-face {font-family: 'ManifoldCF-RegularOblique';
/*src: url('/assets/fonts/ManifoldCF-RegularOblique.eot');
src: url('/assets/fonts/ManifoldCF-RegularOblique.eot#iefix') format('embedded-opentype'),*/src: url('/assets/fonts/ManifoldCF-RegularOblique.woff2') format('woff2'),url('/assets/fonts/ManifoldCF-RegularOblique.woff') format('woff'),url('/assets/fonts/ManifoldCF-RegularOblique.ttf') format('truetype');}

@font-face {font-family: 'ManifoldCF-DemiBold';
/*src: url('/assets/fonts/ManifoldCF-DemiBold.eot');
src: url('/assets/fonts/ManifoldCF-DemiBold.eot#iefix') format('embedded-opentype'),*/src:url('/assets/fonts/ManifoldCF-DemiBold.woff2') format('woff2'),url('/assets/fonts/ManifoldCF-DemiBold.woff') format('woff'),url('/assets/fonts/ManifoldCF-DemiBold.ttf') format('truetype');}

@font-face {font-family: 'ManifoldCF-DemiBoldOblique';
/*src: url('/assets/fonts/ManifoldCF-DemiBoldOblique.eot');
src: url('/assets/fonts/ManifoldCF-DemiBoldOblique.eot#iefix') format('embedded-opentype'),*/src:url('/assets/fonts/ManifoldCF-DemiBoldOblique.woff2') format('woff2'),url('/assets/fonts/ManifoldCF-DemiBoldOblique.woff') format('woff'),url('/assets/fonts/ManifoldCF-DemiBoldOblique.ttf') format('truetype');}

@font-face {font-family: 'ManifoldCF-Bold';
/*src: url('/assets/fonts/ManifoldCF-Bold.eot');
src: url('/assets/fonts/ManifoldCF-Bold.eot#iefix') format('embedded-opentype'),*/src:url('/assets/fonts/ManifoldCF-Bold.woff2') format('woff2'),url('/assets/fonts/ManifoldCF-Bold.woff') format('woff'),url('/assets/fonts/ManifoldCF-Bold.ttf') format('truetype');}

@font-face {font-family: 'ManifoldCF-BoldOblique';
/*src: url('/assets/fonts/ManifoldCF-BoldOblique.eot');
src: url('/assets/fonts/ManifoldCF-BoldOblique.eot#iefix') format('embedded-opentype'),*/src:url('/assets/fonts/ManifoldCF-BoldOblique.woff2') format('woff2'),url('/assets/fonts/ManifoldCF-BoldOblique.woff') format('woff'),url('/assets/fonts/ManifoldCF-BoldOblique.ttf') format('truetype');}

@font-face {font-family: 'ManifoldCF-ExtraBold';
/*src: url('/assets/fonts/ManifoldCF-ExtraBold.eot');
src: url('/assets/fonts/ManifoldCF-ExtraBold.eot#iefix') format('embedded-opentype'),*/src:url('/assets/fonts/ManifoldCF-ExtraBold.woff2') format('woff2'),url('/assets/fonts/ManifoldCF-ExtraBold.woff') format('woff'),url('/assets/fonts/ManifoldCF-ExtraBold.ttf') format('truetype');}

@font-face {font-family: 'ManifoldCF-ExtraBoldOblique';
/*src: url('/assets/fonts/ManifoldCF-ExtraBoldOblique.eot');
src: url('/assets/fonts/ManifoldCF-ExtraBoldOblique.eot#iefix') format('embedded-opentype'),*/src:url('/assets/fonts/ManifoldCF-ExtraBoldOblique.woff2') format('woff2'),url('/assets/fonts/ManifoldCF-ExtraBoldOblique.woff') format('woff'),url('/assets/fonts/ManifoldCF-ExtraBoldOblique.ttf') format('truetype');}
a {color:#000;}
#dealerListH {
	.list_deal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		will-change: transform;
		transition: all .5s ease-in-out;
		transform: scaleY(0);
		transform-origin: center top;
		z-index: 9999;
	}
}
@import "bootstrap";

$green-u: #6e712f;
$green-s: #B6C62C;
$green-c: #C3D52E;
$yellow: #fcb61a;
$red: #e53e2f;
$anthracite-c: rgb(50, 62, 71);
$anthracite-s: #11171f;
$bkg-gray-l: #f1f1ee;
$bkg-gray-m: #e9e9e3;

$transition: all .35s ease-in-out !default;

@mixin var-color {
	&.anthra-d{
		color: $anthracite-s;
	}
	&.anthra-l{
		color: $anthracite-c;
	}
	&.red{
		color: $red;
	}
	&.yellow{
		color: $yellow;
	}
	&.urban{
		color: $green-u;
	}
	&.greenS{
		color: $green-s;
	}
	&.greenC{
		color: $green-c;
	}
	&.white{
		color: white;
	}
	&.gray{
		color: #999;
	}
}

@import "mapo/common";
@import "mapo/index";
@import "mapo/header-footer";
@import "mapo/slideshow";
@import "mapo/lista";
@import "mapo/dettaglio";
@import "mapo/technology";
@import "mapo/storelocator";
@import "mapo/sts";
@import "mapo/aboutus";
@import "mapo/experience";
@import "mapo/contact";
@import "mapo/online-store";
@import "mapo/bbm";
@import "mapo/e2-mblade";
@import "mapo/categoria";
@import "mapo/maintenance-list";
@import "mapo/banner";
@import "mapo/lightning-lp";
@import "mapo/lp-microblade-zetrablade";



html{
	font-size: 15px;
}

a{
	transition: $transition;
	&:hover{
		text-decoration: none;
	}
}

body{
	overflow-x: hidden;
	color: $anthracite-c;
	font-family: 'ManifoldCF-Regular',sans-serif;
	&.lock {
		overflow: hidden;
	}
}

.contbox{
	max-width: 1440px;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
	@media (min-width: 768px){
		padding-left: 30px;
		padding-right: 30px;
	}
	@media (min-width: 992px){
		padding-left: 60px;
		padding-right: 60px;
	}
}

.mt-75{
	margin-top: 75px;
}
.mt-md-75 {
    @media (min-width: 768px){
        margin-top: 75px;
    }
}
.gap-2 {
    gap: .5rem;
}
.gap-3 {
    gap: 1rem;
}

.nav-has-banner{
    margin-top: 90px;
    @include media-breakpoint-up(lg){
        margin-top: 110px;
    }
}

.bkg_antra{
	background-color: $anthracite-s;
}

.bkg_green{
	background-color: $green-c;
}
.bkg_green-s{
	background-color: $green-s;
}

.bkg_gray-light{
	background-color: $bkg-gray-l;
}
.bkg-gray-middle{
	background-color: $bkg-gray-m;
}

.bkg-white{
	background-color: white;
}

ul{
	padding: 0;
	margin: 0;
	list-style: none;
}

p {
	font-size: 15px;
	line-height: 1.4;
	&.bold{
		font-family: 'ManifoldCF-Bold',sans-serif;
	}
	&.green{
		color: $green-c;
	}
}
span{
	color: $anthracite-s;
	&.bold{
		font-family: 'ManifoldCF-Bold',sans-serif;
	}
}

h1, .h1 {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	color: $anthracite-s;
	&.product{
		font-size: 30px;
		text-transform: uppercase;
		@media (min-width: 768px){
			font-size: 40px;
		}
	}
	&.minpx{
		font-size: 30px;
		text-transform: uppercase;
	}
	&.max{
		@media (min-width: 768px){
			font-size: 45px;
		}
		@media (min-width: 992px){
			font-size: 65px;
			line-height: 0.92;
		}
	}
	@include var-color;
}

h2, .h2 {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 28px;
	line-height: 1;
	color: $anthracite-c;
	&.light{
		color: white;
	}
	@include var-color;
}

h3, .h3 {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 20px;
	&.dark{
		color: $anthracite-s;
	}
	&.green{
		color: $green-c;
	}
}

h4, .h4 {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 18px;
	&.dark{
		color: $anthracite-s;
	}
	&.green{
		color: $green-c;
	}
	&.mid{
		font-size: 20px;
	}
}

h5, .h5 {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 15px;
	line-height: 1.2;
	@include var-color;
}

.text-dark  {
    color: $anthracite-s;
}

.main-nav-span {
	font-family: 'ManifoldCF-Bold',sans-serif;
	font-size: 11px;
	line-height: 1;
	color: #989ea3;
	&.light{
		color: #dddddd;
	}
}

.a-main-nav {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 15px;
	color: $anthracite-s;
	&.white{
		color: white;
	}
}

.a-secondary-nav {
	font-family: 'ManifoldCF-Bold',sans-serif;
	font-size: 14px;
	color: $anthracite-c;
}

.a-sticky-nav {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 15px;
	color: #ffffff;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	@media (min-width: 992px){
		&::before{
			content: '';
			position: absolute;
			bottom: -3px;
			left: 5px;
			overflow: hidden;
			width: 0;
			border-bottom: 4px solid $green-c;
			transition: width 0.5s ease;
			transform: skewX(-30deg);
		}
	}
	&:hover{
		color: white;
		@media (min-width: 922px){
			&::before{
				width: calc(100% - 12px);
			}
		}
	}
}

.a-view-all {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 16px;
	line-height: 1.06;
	text-transform: uppercase;
	color: $anthracite-s;
	position: relative;
	display: inline-block;
	@media (min-width: 992px){
		&::before{
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			max-width: 0;
			color: $green-s;
			content: attr(data-hover);
			transition: max-width 0.5s;
			white-space: nowrap;
		}
	}
	&:hover{
		@media (min-width: 992px){
			&::before{
				max-width: 100%;
			}
		}
	}
	&.invert{
		color: $green-c;
		&::before{
			color: white;
		}
	}
}

.btn_primary {
    background-color: #C3D52E;
    border-radius: 0;
    text-transform: uppercase;
    padding: 10px 60px;
    font-family: "ManifoldCF-BoldOblique";
    font-size: 18px;
    line-height: 1.06;
    text-align: center;
    border: none;
}

.a-read-more {
	font-family: 'ManifoldCF-Bold',sans-serif;
	font-size: 13px;
	text-align: center;
	color: $green-s;
	text-transform: uppercase;
}

.span-minicart-count {
	font-family: 'ManifoldCF-Bold',sans-serif;
	font-size: 11px;
	line-height: 0.82;
	color: $anthracite-c;
	&.white{
		color: white;
	}
}

.skate-list-name {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 16px;
	line-height: 1.06;
	color: #242424;
	transition: $transition;
}

.skates-list-price {
	font-family: 'ManifoldCF-DemiBold',sans-serif;
	font-size: 13px;
	line-height: 1.23;
	color: $anthracite-c;
	&.old_price{
		text-decoration: line-through;
		opacity: 0.8;
	}
}

.product-list-category-label {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 11px;
	line-height: 1.45;
	@include var-color;
}

.filters-label {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 15px;
	line-height: 1.07;
	color: $anthracite-c;
}

.span-company-infos {
	font-size: 12px;
	line-height: 1.67;
	color: #999999;
}

.button-label {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	line-height: 1;
	color: white;
	background-color: #B6C62C;
	border: 1px solid #B6C62C;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	&.small{
		font-size: 11px;
	}
}

.box_compra {
	.button-label {
		background-color: #323e47;
		transition: all 0.3s linear;
		border: none;
		font-family: 'ManifoldCF-BoldOblique',sans-serif;
		font-size: 18px;
		line-height: 1.06;
		text-align: center;
		color: #FFF;
		padding: 15px 50px;
		text-transform: uppercase;
		&:hover{
			background-color: #B6C62C;
			cursor: pointer;
		}
	}
}

.button-label-medium {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 12px;
	line-height: 0.92;
	text-align: center;
	color: $anthracite-c;
}

.button-label-big {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 18px;
	line-height: 1.06;
	text-align: center;
	&.white {
		color: #ffffff;
	}
}

.a-footer-nav {
	font-family: 'ManifoldCF-DemiBold',sans-serif;
	font-size: 13px;
	line-height: 1;
	letter-spacing: 0.5px;
	color: white;
	text-transform: uppercase;
	&:hover{
		color: $green-c;
	}
}

.a-footer-ico-nav {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 15px;
	line-height: 1.2;
	color: white;
}

.span-footer {
	font-size: 14px;
	color: #999999;
}
.section-loghi-footer{
    margin-top: 50px;
    @media screen and (max-width: 557px){
            flex-wrap: wrap;
            .tg-logo-footer{
                width: 117px;
            }
        .col-dx-loghi{
            margin-top: 24px;
            transform: translateX(-12px);
        }
    }
    @media screen and (min-device-width: 991px) and (max-device-width: 1088px){
        flex-wrap: wrap;
        .tg-logo-footer{
            width: 117px;
        }
        .col-dx-loghi{
            margin-top: 24px;
            transform: translateX(-12px);
        }
    }
    img {
        width: auto;
        height: auto;
    }
}

.experience-list-item-title {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 18px;
	line-height: 1;
	color: white;
	&.dark{
		color: $anthracite-s;
	}
}

.experience-list-item-title-25 {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 20px;
	line-height: 1;
    color: $anthracite-s;
}

.experience-list-item-big-title {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 20px;
	text-transform: uppercase;
	line-height: 1;
}

.experience-list-item-category {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 12px;
	line-height: 1.33;
	color: $green-s;
	text-transform: uppercase;
}

.experience-list-item-date {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 12px;
	line-height: 1.33;
	color: #999999;
	&.dark{
		color: $anthracite-c;
	}
}

.home-slide-counter {
	font-family: 'ManifoldCF-Bold',sans-serif;
	font-size: 14px;
	letter-spacing: 0.6px;
	color: #ffffff;
}

.span-product-price {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 25px;
	color: $anthracite-c;
	&.dis{
		opacity: 0.8;
		color: #cc0000;
		font-size: 20px;
		text-decoration: line-through;
	}
}

.a-filters-selected {
	opacity: 1;
	font-family: $fontf--extrabol;
	font-size: 15px;
	color: $anthracite-c;
	letter-spacing: 0;
	line-height: 1;
    background-color: transparent;
}

.a-filters-category {
	//opacity: 0.7;
	//font-family: $fontf--extrabol;
    font-weight: 800;
	font-size: 14px;
	color: $anthracite-s;
	letter-spacing: 0;
	line-height: 1;

    &__filter-by {
        strong {
            font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
            color: $anthracite-s;
            font-weight: 800;
            font-size: 14px !important;
        }
        a {
            font-size: 14px !important;
            font-weight: 700 !important;
        }
        .filterby strong {
            font-size: 1rem !important;
        }
    }
}

.a-filters {
	//font-family: $fontf--demibold;
	font-size: 14px;
	line-height: 1;
	color: $anthracite-c;
	&--specialprice {
		display: flex;
		align-items: center;
		margin: 8px 0 0 0;
		img {
			margin-right: 8px;
			position: relative;
			top: -1px;
		}
	}
	&--findsize {
		display: flex;
		align-items: center;
		margin: 8px 0 0 0;
		img {
			margin-right: 8px;
		}
	}
}

.a-filters-price{
	font-family: 'ManifoldCF-DemiBoldOblique',sans-serif;
	font-size: 13px;
	color: $anthracite-c;
}

.a-filters-price-selected{
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 13px;
	color: $anthracite-s;
	text-transform: uppercase;
}

.span-sorting {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 14px;
	line-height: 1.14;
	color: #989ea3;
}

.span-15px-eb-it {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 15px;
	line-height: 1.4;
	color: $anthracite-s;
	@include var-color;
}

.span-15px-semibold-italic{
	font-family: 'ManifoldCF-DemiBoldOblique',sans-serif;
	font-size: 15px;
	line-height: 1.4;
	color: $anthracite-c;
}

.span-category-description {
	font-family: 'ManifoldCF-Bold',sans-serif;
	font-size: 12px;
	line-height: 1.58;
}

.span-product-search {
	font-family: 'ManifoldCF-DemiBold',sans-serif;
	font-size: 30px;
	font-style: oblique;
	letter-spacing: -0.5px;
	color: #c8c8c8;
}

.span-product-category {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 13px;
	line-height: 1.38;
	color: #ffffff;
}

.span-14gr {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 14px;
	line-height: 1.2;
	color: #92989d;
	&.green{
		color: $green-c;
	}
}

.span-13px {
	font-size: 13px;
	line-height: 1.38;
	@include var-color;
	&.boit{
		font-family: 'ManifoldCF-BoldOblique',sans-serif;
	}
	&.link{
		font-family: 'ManifoldCF-Bold',sans-serif;
	}
}

.span-18px {
	font-family: 'ManifoldCF-DemiBold',sans-serif;
	font-size: 18px;
	line-height: 1.33;
	letter-spacing: 1px;
	text-transform: uppercase;
	&.white{
		color: white;
	}
}

.span-title-65{
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 25px;
	line-height: 0.92;
	color: white;
	@media (min-width: 768px){
		font-size: 45px;
	}
	@media (min-width: 1024px){
		font-size: 65px;
	}
}

.mb-80 {
    margin-bottom: 5rem;
}

.btnCloseModal {
    font-size: 2rem;
}

.span-product-list-keywords {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 11px;
	line-height: 1.38;
	text-transform: uppercase;
	color: #989ea3;
}

.span-product-detail-keywords {
	font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
	font-size: 13px;
	line-height: 1.46;
	color: $anthracite-c;
	text-transform: uppercase;
}

.label-bread-dett {
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 13px;
	line-height: 13px;
	margin-bottom: 0;
	text-transform: uppercase;
}

.span-events-date-big{
	font-family: 'ManifoldCF-DemiBold',sans-serif;
	font-size: 50px;
	line-height: 1;
	color: white;
}

.form-label{
	font-family: 'ManifoldCF-BoldOblique',sans-serif;
	font-size: 13px;
	line-height: 1.46;
	text-transform: uppercase;
}

.li_breadcrumb{
	font-family: 'ManifoldCF-DemiBold',sans-serif;
	font-size: 13px;
	line-height: 1;
	color: white;
	text-transform: capitalize;
	opacity: 0.7;
	&::before{
		color: white !important;
	}
	&.sel{
		opacity: 1;
	}
}

input{
	outline: none !important;
	box-shadow: none !important;
}

.lightwidget__link {
	overflow: hidden;
	.lightwidget__photo {
		transition: all .5s;
	}
	&:hover {
		.lightwidget__photo {
			transform: scale(1.05);
		}
	}
}

.exp-title-res {
	h2 {
		border-bottom: 1px solid $green-c;
		color: $green-c;
		padding-bottom: 15px;
		margin-bottom: 30px;
		margin-top: 60px;
	}
}

.mt-30 {
	margin-top: 30px;
}

.buyonlinehidden{
	font-family: 'ManifoldCF-RegularOblique',sans-serif;
	color: $green-s;
	font-size: 13px;
}

.nightline{
	border-top: 2px solid #87e3fe;
}
.list_xsonight{
	h3{
		margin-top: 15px;
	}
}

.mob-140{
	width: 140% !important;
	@media (min-width: 768px){
		width: 100% !important;
	}
}

.no-opaticy {

    opacity: 1;
}

.listOrders {margin: 0; padding: 0;}
.oldItemsOrder {border-bottom: 1px solid #D2D2D2; display: block; font-size: 14px; color: #666; font-weight: 400; position: relative;}
.oldItemsOrder .cartListItem, .oldItemsOrder .cartListItem li {min-height: 60px;}
.cart .cartHead {display: block; border-bottom: 1px solid #D2D2D2; margin: 0.5em 1em; min-height: 30px;}
.cart .cartHead li{display: block; padding:0.3em 2%; font-size: 12px; color: #999; float: left; font-family: ProximaNova-Semibold;}
.cart .cartListItem:last-child{border-bottom: none}
.cart .cartListItem::after {content: ' '; display: table; clear: both;}
.cart .article {width: 49%;}
.cart .size {width: 18%;}
.cart .price {width: 18%;}
.cart .quantity {width: 15%;}
.oldorder {width: 95%; float: none; clear: both; margin-left: 5%; display: none; background: #f4f4f4; padding: 0 0 10px 0;}
.clear{clear: both}


//helpers
.text-green-c{
    color: $green-c;
}

.mb-8{
    margin-bottom: 5rem;
}
.mb-lg-8{
    @include media-breakpoint-up(lg){
        margin-bottom: 5rem;
    }
}

.top-50{
    top:50%
}

.translateY-n50{
    transform: translateY(-50%);
}

.dispay-31{
    @include media-breakpoint-up(xl){
        font-size: 4.0625rem;
    }
}

.nav-top-space {
    margin-top: 75px;
    @include media-breakpoint-up(lg) {
        margin-top: 70px;
    }
}
.d-xl-none {
    @include media-breakpoint-up(xl) {
        display: none !important;
    }
}

.pe-none {
    pointer-events: none !important;
}

.filter-invert-1 {
    filter: invert(1);
}

.btn-reset {
    appearance: none;
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
}

//used in micro-blade
.hero{
    .hero-content {
        .title{
            color: $black;
            font-size: 2rem;
            font-weight: 800;
            line-height: 0.92;
            @include media-breakpoint-up(md){
                font-size: 3rem;
            }
            @include media-breakpoint-up(lg){
                color: $white;
                font-size: 4.3333rem;
            }
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            left: 50%;
            color: $white;
            padding: 0 0 0 4rem;
            transform: translate(-50%, -50%);
        }
    }
}

// Image lazy spinner
.boximg:has(.lazy),
.articles-image-block:has(.lazy)
.highlightProducts__slide-thumb:has(.lazy),
.boxcol:has(.lazy),
.mte__box:has(.lazy),
{
    position: relative;
    aspect-ratio: 1;
    .lazy {
        opacity: 0;
        transition: opacity .4s, scale .4s, transform .4s;
    }

    &:after {
        content: "";
        width: 3rem;
        height: 3rem;
        position: absolute;
        background: url(../img/loader-icon.svg) center / contain no-repeat;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: fadeOut 1s ease-out forwards;

        img {
            animation: spin 1s linear infinite;
        }
    }

    &.media-loaded {
        &:after {
            content: none
        }

        .lazy {
            opacity: 1;
        }
    }
}
