.maintenance-list {
    &__spacer-x{
        margin-left: -15px!important;
        margin-right: -15px!important;
    }
    &__spacer-top-10 {
        margin-top: 10px;
    }

    &__spacer-top-15 {
        margin-top: 15px;
    }

    &__spacer-top-30 {
        margin-top: 30px !important;
    }

    &__spacer-bottom-30 {
        margin-bottom: 30px !important;
    }

    &__spacer-top-60 {
        margin-top: 60px;
    }
    &__spacer-bottom-60 {
        margin-bottom: 60px;
    }

    &__guides-slider{
        margin-right: -16px;
        margin-left: -16px;
        margin-top: 30px;
        &-detail{
            .img-slide-container{
                //height: 510px;
                background: white;
                img{
                    //margin: auto;
                    //height: 100%;
                    width: auto;
                   /* left: 50%;
                    transform: translate(-50%, 0);*/
                }
            }

            .slick-dots {
                display: flex;
                justify-content: center;
                margin: 0;
                padding: 1rem 0;

                list-style-type: none;

                li {
                    margin: 0 0.25rem;
                }

                button {
                    display: block;
                    width: 20px;
                    height: 3px;
                    padding: 0;

                    border: none;

                    background-color: #cccccc;

                    text-indent: -9999px;

                    &:focus{
                        outline: 0;
                    }
                }

                li.slick-active button {
                    background-color: #c3d52e;
                }
            }
        }
    }
    &__lateral {
        &__title {
            font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
            font-size: 30px !important;
            color: #11171f;
        }
        &__marker{
            background: #c3d52e;
            color: #fff;
            border-radius: 50%;
            font-size: 12px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    &__input {
        &__search {
            background: #f1f1ee !important;
            padding: 15px;
            height: 52px;
            border: none !important;
            font-size: 15px;
            outline: none!important;
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #323e47;
                opacity: 1; /* Firefox */
            }
        }
    }

    &__link-item {
        a {
            text-transform: uppercase;
            padding: 15px 0 15px 0;
            background-image: url("../img/2021/ico-plus-filters.svg");
            background-repeat: no-repeat;
            background-position: right 16px center;
            &:hover{
                opacity: 0.5;
            }
            &.sel{
                strong {
                    font-family: $fontf--bold;
                }
            }
            &.opened {
                background-image: url("../img/2021/ico-close.svg");
            }
        }
        border-bottom: 2px solid #dbdbdb;
        opacity: 0.7;
        font-size: 15px;
        font-family: "ManifoldCF-ExtraBold";
        font-weight: 800;
        line-height: 1.07;
        color: #323e47;
        span {
            border: 0;
            width: 20px;
            height: 20px;

            padding: 3px 6px 5px;
            background-color: #c3d52e;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
        }
    }

    &__content {
        &__section-prod{
            margin-bottom: 30px!important;
        }
        &__title {
            font-size: 40px;
            color: #C3D52E;
            text-transform: uppercase;
        }

        &__name{
            font-family: "ManifoldCF-Bold", sans-serif !important;
        }
        &__keywords{
            font-family: "ManifoldCF-Regular", sans-serif !important;
            font-size: 16px !important;
        }

        &__slider {
            &__container {
                background: #11171f;
                padding: 30px;
            }
            &__title{
                color: #989ea3;
                margin-bottom: 0;
            }
            &__arrow{

                &__next{
                    position: absolute;
                    top: calc(-26px + -30px);
                    right: 0px;
                    padding-right: 16px;
                    cursor: pointer;
                    display: none !important;
                    @include media-breakpoint-up(md){
                        display: inline !important;
                    }
                }
                &__prev{
                    position: absolute;
                    top: calc(-26px + -30px);
                    right: 0px;
                    padding-right: calc(44px + 26px);
                    cursor: pointer;
                    display: none !important;
                    @include media-breakpoint-up(md){
                        display: inline !important;
                    }
                }
            }
        }
        &__marker{
            min-width: 26px;
            height: 26px;
            font-size: 18px;
            font-family: "ManifoldCF-ExtraBold";
            margin-right: 15px;
        }
        &__skate-list-name {
            &:before {
                border-bottom: 2px solid #b6c62c;
                bottom: -3px;
                content: "";
                left: 0;
                overflow: hidden;
                position: absolute;
                transform: skewX(-30deg);
                transition: width .5s ease;
                width: 0;
                z-index: -1;
                width: 100%;
            }
        }

    }

    &__detail{
        &__download-container{
            padding-top: 30px;
            padding-left: 30px;
            background: #f1f1ee;
            &__title{
                font-family: ManifoldCF-Bold;
                margin-bottom: 0;
                text-transform: uppercase;
                line-height: 1.4;
            }
            &__info{
                font-family: ManifoldCF-Regular;
                font-size: 13px;
                line-height: 1.38;

            }
        }
    }
}

#sidemenu-maintenance-23{
    padding: 0;
    list-style: none;
    li{
        border-top: 1px solid #D8D8D8;
        &:last-child {
            border-bottom: 1px solid #D8D8D8;
        }
        span{
            width: 7px;
            height: 7px;
            border-radius: 7px;
            border: 1px solid #0b2e13;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
        a{

        }
        .sott_filCat{
            display: none;
            padding-bottom: 8px;
            li {
                border: none;
                a {
                    background: none;
                    padding: 8px 0;
                    strong {
                        display: flex;
                        img {
                            margin-right: 8px;
                        }
                    }
                }
            }
            span{
                border: none;
                background-color: #cccccc;
            }
        }
    }
}

.sott_maintenance{
    display: none;
    padding-bottom: 8px;
    li {

        padding: 8px 0 8px 27.5px;
        border: none;
        a {
            background: none;
            padding: 8px 0;
        }
    }
    span{
        border: none;
        background-color: #cccccc;
    }
}


