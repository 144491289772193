/* carosel int info */
.carosel_info.info_int{
	background-color: #282e35;
	padding: 10px;
	border: none;
	margin-bottom: -75px;
	.item{
		p{
			color: white;
			font-size: 14px;
			font-family: 'ManifoldCF-DemiBold',sans-serif;
		}
		a{
			color: white;
			border-color: $green-c !important;
			&:hover{
				background-color: $green-c;
				color: $anthracite-s;
			}
		}
	}
	.owl-nav{
		svg{
			path{
				fill: white;
			}
		}
	}
}
#list_container{
	transition: all .5s;
	opacity: 0;

	&.loaded{
		opacity: 1;
		transform: translateY(0);
	}
	.box_cerca{
		margin-bottom: 23px;
	}
}

.list_container-transform {
    //transform: translateY(100px);
}

.list_container-transform-ecommerce {
    transform: translateY(75px);
    @media (min-width: 768px){
        transform: translateY(100px);
    }
}
.page_internati .list_container-transform-ecommerce {
    transform: translateY(0);
}

.list_product{
	&:after{
		content: '';
		display: table;
		clear: both;
	}
	.colsx-banner{
		background-size: cover;
		background-position: center;
		text-align: left;
		transition: $transition;
		overflow: hidden;
		min-height: 106px;
		margin-bottom: 30px;
		&.skate{
			background-image: url("../img/banfil.jpg");
		}
		&.size{
			background-image: url("../img/banner-taglie.jpg");
		}
		&.acc{
			background-image: url("../img/banner-guide-mantainance.jpg");
		}
		a{
			padding: 25px;
			display: block;
			width: 65%;
		}
		&:hover{
			a{
				transform: scale(1.1);
			}
		}
	}
}
/* filtri mobile */
.size-guide-fancy {
	svg {
		circle {
			transition: all .5s;
		}
	}
	&:hover {
		svg {
			circle {
				fill: $green-c;
			}
		}
	}
}
.filtri_mob {
	margin: 50px 0 0 0;
	.fil_sx{
		border-right: 1px solid #ccc;
	}
	.fil_sx, .fil_dx{
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
	}
	.fil_dx{
		.dropdown-menu{
			background-color: $anthracite-s;
			border: none;
			border-radius: 0;
			margin: 0;
			width: 100%;
			a{
				color: $green-c;
			}
		}
	}
	.col-filtri {
		opacity: 0;
		transition: $transition;
		height: 0;
	}
	.icn_x, .btn-fil{
		display: none;
	}
	&.openf {
		background-color: $bkg-gray-m;
		position: fixed;
		z-index: 9999;
		top: 0;
		left: 0;
		right: 0;
		height: 100vh;
		overflow: hidden;
		margin: 0;
		.col-filtri {
			background-color: white;
			margin-right: 5px;
			margin-left: 5px;
			position: relative;
			height: 70vh;
			overflow-y: auto;
			opacity: 1;
		}
		.fil_sx.col-12{
			border: none;
		}
		.fil_dx, .minpx{
			display: none;
		}
		.icn_x{
			display: block;
			cursor: pointer;
		}
		.btn-fil{
			display: block;
		}
		.apfil{
			background-color: $green-c;
			padding-top: 10px;
			padding-bottom: 10px;
		}
		.resetfil{
			border: 1px solid #ccc;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
    .h1 {
        font-family: 'ManifoldCF-ExtraBoldOblique',sans-serif;
        color: $anthracite-s;
    }
}
/* style filtri */
.col-filtri{
	@media (min-width: 768px){
		width: 40%;
		float: left;
		opacity: 1;
	}
	@media (min-width: 992px){
		width: 23%;
	}
	.closef{
		text-align: center;
		cursor: pointer;
		margin: 0;
		&.ics{
			padding: 15px 0;
		}
		&.apfil{
			text-transform: uppercase;
			font-family: 'ManifoldCF-Bold';
			background-color: #fff;
			padding: 10px;
			font-size: 16px;
		}
	}
	.filters-label{
		text-transform: uppercase;
		&.bord{
			border-top: 1px solid #E8E8E8;
			padding-top: 25px;
			margin-top: 25px;
		}
	}
	.genFilter{
		//border-color: $green-c !important;
		//padding-top: 25px;
		.no_all{
			background-color: $green-c;
			display: block;
			padding: 15px;
		}
		.generi{
			display: flex;
			align-items: center;
            gap: .5rem;
            .sel {
                background-color: $green-c;
            }

			a{
				margin-right: 20px;
				.icon{
					height: 20px;
                    width: 20px;
					min-width: 20px;
					opacity: 0.55;
					transition: $transition;
                    background-size: 20px 20px !important;
				}
				p{
					margin-top: 7px;
					text-align: center;
					text-transform: capitalize;
					opacity: 0.55;
					transition: $transition;
					position: relative;
                    font-size: 12px;
                    font-weight: 800;
				}
				&:hover, &.sel{
					.icon, p{
						opacity: 0.8;
					}
					p {
						&:after {
							width: 100%;
						}
					}
				}
				&:last-of-type{
					margin-right: 0;
				}
			}
			.big_button_all .icon{
				background: url("../img/g-all.svg") no-repeat center bottom;
			}
			.big_button_28 .icon{
				background: url("../img/g-man.svg") no-repeat center bottom;
			}
			.big_button_29 .icon{
				background: url("../img/g-woman.svg") no-repeat center bottom;
			}
			.big_button_30 .icon{
				background: url("../img/g-kind.svg") no-repeat center bottom;
			}
			.icon-gemen{
				background: url("../img/g-man.svg") no-repeat center bottom;
			}
			.icon-gewo{
				background: url("../img/g-woman.svg") no-repeat center bottom;
			}
			.icon-geboy{
				background: url("../img/g-boy.svg") no-repeat center bottom;
			}
			.icon-gegirl{
				background: url("../img/g-girl.svg") no-repeat center bottom;
			}
            .col {
                padding: 0.75rem;
                border: 1px solid #E6E8E9;
                border-radius: 4px;
                transition: all 0.35s ease-in-out;
                &:hover {
                    border: 1px solid #C3D52E;
                }
            }
		}
	}
	.catFilter{
		#filtri{
			padding: 0;
			list-style: none;
			li{
				border-top: 1px solid #D8D8D8;
				&:last-child {
					border-bottom: 1px solid #D8D8D8;
				}
				span{
					width: 7px;
					height: 7px;
					border-radius: 7px;
					border: 1px solid #0b2e13;
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
				}
				a{
					text-transform: uppercase;
					display: block;
					padding: 16px 0px;
					background-image: url("../img/2021/ico-plus-filters.svg");
					background-repeat: no-repeat;
					background-position: right 16px center;
                    background-size: 12px 12px;
					&.sel{
						strong {
							font-family: $fontf--bold;
						}
					}
					&.opened {
						background-image: url("../img/2021/ico-close.svg");
					}
				}
				.sott_filCat{
					display: none;
					padding-bottom: 8px;
					li {
						border: none;
						a {
							background: none;
							padding: 8px 0;
							strong {
								display: flex;
								img {
									margin-right: 8px;
								}
							}
						}
					}
					span{
						border: none;
						background-color: #cccccc;
					}
				}
			}
		}
        .buy-online-filter {
            strong  {
                font-size: 14px;
            }
        }
        #filtri-cat-2023{
        padding: 0;
        list-style: none;
        li{
            border-top: 1px solid #D8D8D8;
            &:last-child {
                border-bottom: 1px solid #D8D8D8;
            }
            span{
                width: 7px;
                height: 7px;
                border-radius: 7px;
                border: 1px solid #0b2e13;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
            }
            a{
                text-transform: uppercase;
                display: block;
                padding: 16px 0px;
                background-repeat: no-repeat;
                background-position: right 16px center;
                &:hover{
                    opacity: 0.5;
                }
                &.sel{
                    strong {
                        font-family: $fontf--bold;
                    }
                }
            }
            .sott_filCat{
                display: none;
                padding-bottom: 8px;
                li {
                    border: none;
                    a {
                        background: none;
                        padding: 8px 0;
                        strong {
                            display: flex;
                            img {
                                margin-right: 8px;
                            }
                        }
                    }
                }
                span{
                    border: none;
                    background-color: #cccccc;
                }
            }
        }
    }
	}
	.tagFilter{
		#filtri_taglia{
			padding: 0;
			list-style: none;
			clear: both;
			li{
				display: inline-block;
				width: 16.65%;
				border-bottom: 1px solid #f6f6f6;
				border-right: 1px solid #f6f6f6;
				a{
					background-color: #fff;
					padding: 15px 3px;
					text-align: center;
					display: block;
					font-size: 13px;
					&.sel{
						font-family: 'ManifoldCF-Bold';
						color: #fff;
						background-color: #454545;
					}
					&:hover{
						background-color: #dcdcdc;
					}
				}
				&.tuttetg{
					width: 100%;
				}
			}
		}
	}
	.dropFilter{
		position: relative;
		border-top: 1px solid;
		border-color: #E8E8E8 !important;
		&:last-of-type{
			border-bottom: 1px solid #E8E8E8;
		}
		span{
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 50%;
			margin-top: -8px;
			z-index: 1;
		}
		.btn{
			transition: $transition;
			width: 100%;
			border: none;
			border-radius: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px 12px 15px 15px;
			@media (min-width: 768px) and (max-width: 1280px){
				padding: 15px 12px 15px 70px;
			}
			cursor: pointer;
			text-transform: uppercase;
			background: transparent;
			box-shadow: none !important;
			&.dropdown-toggle::after {
				display: inline-block;
				width: auto;
				height: auto;
				margin: 0;
				vertical-align: middle;
				content: "+";
				color: #989ea3;
				border: none;
				position: absolute;
				right: 20px;
			}
			&:hover{
				color: $green-c;
			}
		}
		.dropdown-menu{
			a{
				display: block;
				font-size: 14px;
				margin-left: 5px;
				cursor: pointer;
			}
			&.show{
				transform: translate3d(0px, 0px, 0px) !important;
				width: 65%;
				left: auto !important;
				right: 0;
				border: none;
				background-color: $anthracite-s;
				border-radius: 0;
				padding: 10px;
				margin-top: -1px;
				a{
					color: #fff;
					line-height: 25px;
					margin: 0;
					&:hover{
						color: $green-c;
					}
				}
			}
		}
	}
	.prezFilter{
		#filtri_prezzo{
			div{
				background-color: $bkg-gray-l;
				min-height: 45px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: 2px solid #fff;
				border-right: 2px solid #fff;
				transition: $transition;
				@media (max-width: 767px){
					&:nth-child(2n){
						border-right: none;
					}
				}
				@media (min-width: 768px){
					&:nth-child(3n){
						border-right: none;
					}
				}
				&.sel{
					background-color: $green-c;
					&:hover{
						background-color: $green-c;
					}
				}
				a{
					display: block;
					text-align: center;
				}
				&:hover{
					background-color: rgba(195, 213, 46, 0.5);
				}
			}
		}
		#filtri_size{
            gap:8px;
            display: inline-flex;
            flex-wrap: wrap;

            div{
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #E6E8E9;
                border-radius: 4px;
                padding:12px 8px;
				transition: $transition;
                flex: 0 1 calc(25% - 8px);
				&.sel{
					background-color: $green-c;
					&:hover{
						background-color: $green-c;
					}
				}
				a{
					display: block;
					text-align: center;
				}
				&:hover{
                    border: 1px solid $green-c;
				}
			}
		}
	}
	.cosasapere{
		ul{
			padding-left: 20px;
			//list-style: url("../img/ico-check.svg");
			list-style: circle;

			a{
				display: inline-block;
				position: relative;
				&::before{
					content: '';
					position: absolute;
					bottom: -3px;
					left: 0;
					overflow: hidden;
					width: 0;
					border-bottom: 1px solid $green-c;
					transition: width 0.5s ease;
					transform: skewX(-30deg);
				}
				&:hover{
					&::before {
						width: 100%;
					}
				}
			}
		}
	}
    h1, .h1 {
        font-size: 32px;
        font-family: "ManifoldCF-ExtraBoldOblique", sans-serif;
        color: $anthracite-s;
    }
    strong {
        font-size: 14px;
        font-weight: bolder;
    }
}
/* header category */
.prodList{
	.listTitle{
		overflow: hidden;
		margin: 0;
		margin-bottom: 30px;
		.imgCat{
			position: relative;
			.triangolo{
				display: none;
				@media (min-width: 992px){
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 300px 50px;
					border-color: transparent transparent $bkg-gray-l transparent;
					position: absolute;
					top: 0;
					right: 0;
				}
			}
			&.dispari{
				.triangolo{
					right: auto;
					left: 0;
					border-width: 300px 50px 0 0;
					border-color: $bkg-gray-l transparent transparent transparent;
				}
			}
		}
		.span-category-description{
			opacity: 0.7;
		}
		.span-product-category{
			color: $green-s;
		}
		.col-12{
			.txt_category{
				width: 100%;
				svg{
					position: absolute;
					right: 60px;
					top: 30%;
				}
			}
		}
	}
}

/* lista prodotti */
.prodListBox{
	//padding: 0 15px;
    z-index: 100;
    position: relative;
	@media (min-width: 768px){
		padding: 0 30px;
		width: 60%;
		float: right;
	}
	@media (min-width: 992px){
		width: 75%;
	}
	.prodList {
		margin-bottom: 60px;
		border-bottom: 2px solid #E8E8E8;


	}
	#list_container{
		.cerca_fil{
			/*margin-bottom: 23px;*/
		}
		/* cerca */
		.box_cerca{
			input{
				outline: none !important;
				border: none;
			}
		}
		.lista_prodotti_iso{
			margin: 0;
			@media (min-width: 992px){
				margin-right: -15px;
				margin-left: -15px;
			}
			a{
				@media (min-width: 768px){
					min-height: 220px;
				}
				text-align: center;
				position: relative;
				display: block;
				margin-bottom: 30px;
				.boximg{
					overflow: hidden;
					img{
						margin: 0 auto;
						transition: $transition;
						width: 80%;
					}
				}
				.skate-list-name{
					position: relative;
					display: inline-block;
					@media (min-width: 992px){
						&::before{
							content: '';
							position: absolute;
							bottom: -3px;
							left: 0;
							overflow: hidden;
							width: 0;
							border-bottom: 2px solid $green-s;
							transition: width 0.5s ease;
							z-index: -1;
							transform: skewX(-30deg);
						}
					}
				}
				.price_prodev{
					.old_price{
						text-decoration: line-through;
						font-size: 12px;
						color: #cc0000;
						margin-right: 5px;

					}
				}
				&:hover{
					img{
						transform: scale(1.1);
					}
					.skate-list-name{
						@media (min-width: 992px){
							&::before{
								width: 100%;
							}
						}
					}
				}
				.label{
					display: none;
					position: absolute;
					top: 20px;
					right: 20px;
					img{
						margin-bottom: 10px;
						width: auto;
						float: right;
						clear: both;
					}
					.lb_prem{
						height: 30px;
					}
					.hei8{
						height: 8px;
					}
					.hei30{
						height: 30px;
					}
				}
			}
		}
	}
}

/* cerca + low hig price */
.cerca_fil{
	.dropdown{
		button{
			outline: none !important;
			box-shadow: none;
			padding: 10px 15px;
			border: none;
		}
		&.show{
			button{
				background-color: $anthracite-s;
				color: #fff;
				transition: background-color 0.35s linear;
				border-radius: 0;
			}
			.dropdown-menu{
				transition: background-color 0.35s linear;
				background-color: $anthracite-s;
				color: #fff;
				border: none;
				border-radius: 0;
				margin: 0;
				margin-right: -1px;
				background-clip: border-box;
				a{
					padding: 10px 30px;
					&:hover{
						background-color: transparent;
						color: $green-c;
					}
				}
			}
		}
	}
}

@media (max-width: 767px){
	.hidden-md{display: none;}
}

/* Spare Parts */
.fil_det_prod{
	margin-bottom: -75px;
	display: none;
	.p-4{
		padding: 30px !important;
	}
	.fil_prod{
		border-color: white !important;
	}
}


/* lista risultati */
.sectionTitleRes{
	h2{
		span{
			color: $green-c;
			text-transform: uppercase;
		}
	}
}

.bollino_new,.bollino_sale {
	position: absolute;
	right: 0;
	top: 0;
	background: $green-c;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: underline;
	font-style: italic;
	font-weight: 600;
	padding-right: 2px;
	padding-bottom: 2px;
	z-index: 10;
}

.bollino_buy {
	position: absolute;
	right: 0;
	top: 0;
	background: $green-c;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	//font-style: italic;
    line-height:1;
	font-weight: 600;
	padding-right: 2px;
	padding-bottom: 2px;
	z-index: 10;


}

.bollino_new24 {
	position: absolute;
	left: 0;
	top: 0;
	background: $anthracite-s;
	color: $green-c;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	//font-style: italic;
    line-height:1;
	font-weight: 600;
	padding-right: 2px;
	padding-bottom: 2px;
	z-index: 10;


}

.out_stock {
    background: transparent;
}

.bollino_sale {
	background: $anthracite-c;
	color: #FFF;
}



.prodListRes{
	a{
		@media (min-width: 768px){
			min-height: 220px;
		}
		text-align: center;
		position: relative;
		display: block;
		margin-bottom: 30px;
		.boximg{
			overflow: hidden;
			img{
				margin: 0 auto;
				transition: $transition;
				width: 80%;
			}
		}
		.skate-list-name{
			position: relative;
			display: inline-block;
			@media (min-width: 992px){
				&::before{
					content: '';
					position: absolute;
					bottom: -3px;
					left: 0;
					overflow: hidden;
					width: 0;
					border-bottom: 2px solid $green-s;
					transition: width 0.5s ease;
					z-index: -1;
					transform: skewX(-30deg);
				}
			}
		}
		.price_prodev{
			.old_price{
				text-decoration: line-through;
				font-size: 12px;
				color: #828282;
				margin-right: 5px;
			}
		}
		&:hover{
			img{
				transform: scale(1.1);
			}
			.skate-list-name{
				@media (min-width: 992px){
					&::before{
						width: 100%;
					}
				}
			}
		}
		.label{
			display: none;
			position: absolute;
			top: 20px;
			right: 20px;
			img{
				margin-bottom: 10px;
				width: auto;
				float: right;
				clear: both;
			}
			.lb_prem{
				height: 30px;
			}
			.hei8{
				height: 8px;
			}
			.hei30{
				height: 30px;
			}
		}
	}
}
.am_price{
	.span-product-price{
		color: $green-s;
		font-size: 20px;
		&.dis{
			color: #aaaaaa;
			margin-left: 15px !important;
			font-size: 16px;
			text-decoration: line-through;
		}
	}
}

.special-offers__checkbox {
	position: relative;
	cursor: pointer;
	display: inline-block;
	margin-bottom: 15px;
	input[type=checkbox] {
		position: absolute;
		opacity: 0;
	}
	label {
		cursor: pointer;
		position: relative;
		margin-left: 10px;
		font-size: 16px;
		top: 2px;
		letter-spacing: .5px;
		padding: 0 8px 2px;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: -5px;
			right: -5px;
			background-color: $green-s;
			-webkit-transition: background-color 0.35s ease-in-out;
			transition: background-color 0.35s ease-in-out;
			-webkit-transform: skewX(-20deg);
			transform: skewX(-20deg);
			z-index: -1;
		}
	}
	.span-check {
		background-color: #fff;
		left: 0;
		top: 2px;
		width: 13px;
		height: 13px;
		border: 2px solid #ccc;
		content: '';
		display: inline-block;
		position: relative;
		transition: all .5s;
	}
	.span-checked {
		content: '';
		width: 10px;
		height: 10px;
		background-image: url(../img/ico-check-2.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		left: 2px;
		top: 7px;
		position: absolute;
		z-index: 10;
		transition: all .5s;
		opacity: 0;
	}
	&:hover {
		.span-check {
			border: 2px solid $green-s;
		}
	}
}

.livewire-sticky-column {
    position:sticky;
    top:0;
}

.category-filters25-wrapper {
    border-bottom: 1px solid #E6E8E9;
    gap: .5rem;
    .category-filters25-item {
        border: 1px solid #E6E8E9;
        border-radius: 4px;
        width: 25% !important;
        @media (max-width: 768px){
            width: calc(100% - 4px) !important;
            height: 5.5rem;
        }
        &:has(.box-category-selected) {
            border-radius: 4px;
            border: 1px solid #CEDD51;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        }
    }
    img {
        width: 40px;
    }
    strong {
        font-size: 14px;
        color: $anthracite-c;
    }
    .sel {
        background-color: #CEDD51;
    }
}

.news-filters25-wrapper {
    @extend .category-filters25-wrapper;
    border-bottom: none;
    gap: 1rem;
    &> div {
        border: none;
        gap: 1rem;
    }
    .slick-initialized .slick-slide {
        @media (max-width: 992px){
            margin: 0 .25rem;
        }
    }
    .news_filters .slick-slide {
        @media (max-width: 992px){
            width: fit-content !important;
        }
    }
    .news-filters25-item {
        border: 1px solid #E6E8E9;
        border-radius: 4px;
        transition: .3s;
        @media (max-width: 992px){
            width: auto !important;
        }
        &:has(.box-category-selected) {
            border-radius: 4px;
            border: 1px solid #CEDD51;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        }
        &:hover {
            border: 1px solid #CEDD51;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        }
    }
    img {
        width: 40px;
    }
    strong {
        font-size: 12px;
        color: $anthracite-c;
    }
    .sel {
        background-color: #CEDD51;
        strong {
            color: $anthracite-s;
        }
    }
}


//price range slider
.range-wrapper {
    position: relative;
    width: 100%;
    input {
        position: absolute;
        width: 100%;
        height: 5px;
        background: none;
        pointer-events: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        top: 50%;
        transform: translateY(-50%);
    }
    input::-webkit-slider-thumb {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        outline: 6px solid #C3D52E;
        outline-offset: -6px;
        /*border: 6px inset rgb(182,198,44);*/
        pointer-events: auto;
        -webkit-appearance: none;
        cursor: pointer;
        margin-bottom: 1px;
    }
    input::-moz-range-thumb {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        outline: 6px solid #C3D52E;
        outline-offset: -6px;
        /*border: 6px inset rgb(182,198,44);*/
        background-color: white;
        pointer-events: auto;
        -moz-appearance: none;
        cursor: pointer;
        margin-top: 30%;
    }
}

.double_range_slider_box {
    position: relative;
    width: 500px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.double_range_slider {
    width: 90%;
    height: 4px;
    position: relative;
    background-color: #E6E8E9;
    border-radius: 10px;
}
.range_track {
    height: 100%;
    position: absolute;
    border-radius: 20px;
    background-color: #C3D52E;
}

.minvalue {
    position: absolute;
    padding: 6px 15px;
    background: #0e5f59;
    border-radius: 1rem;
    color: white;
    bottom: 0;
    transform: translate(0, -100%);
    left: 0;
    font-size: 1rem;
    transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: left, transform;
}
.maxvalue {
    position: absolute;
    padding: 6px 15px;
    background: #0e5f59;
    border-radius: 1rem;
    color: white;
    top: 0;
    transform: translate(0, -100%);
    right: 0;
    font-size: 1rem;
    transition: right 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: right, transform;
}

//stile read more
.list_product .descr_prod {
    margin-top: 1.5rem;
    .descr_prod_text {
        max-height: 50px;
        overflow: hidden;
        transition: all 1s cubic-bezier(0, 1, 0, 1);
        &.view {
            max-height: 100vh;
            transition: all 1s ease-in-out;
        }
    }
    .descr_prod div.view {
        max-height: 100vh;
        transition: all 1s ease-in-out;
    }
}

//loader
.processing-loader {
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    top: 0;
    text-align: center;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    animation: fadeOut 1s ease-out forwards;
    animation-delay: 2s;
    overflow: hidden;
    position: fixed;
}
.processing-loader img {
    animation: spin 1s linear infinite;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 50%);
}

//price range slider
.double_range_slider {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: #e6e6e6;
    border-radius: 3px;
    margin: 20px 0;
}
.range_track {
    position: absolute;
    height: 100%;
    //background-color: #C3D52E; /* Colore visibile per il range selezionato */
    border-radius: 3px;
    pointer-events: none;
}
input[type="range"] {
    position: absolute;
    width: 100%;
    height: 6px;
    background: none;
    -webkit-appearance: none;
    //pointer-events: auto;
    z-index: 2;
}
//input[type="range"]::-webkit-slider-thumb {
//    -webkit-appearance: none;
//    width: 16px;
//    height: 16px;
//    background-color: white;
//    border: 2px solid #ffffff;
//    border-radius: 50%;
//    cursor: pointer;
//    z-index: 2;
//}
//input[type="range"]::-moz-range-thumb {
//    width: 16px;
//    height: 16px;
//    background-color: white;
//    border: 2px solid #ffffff;
//    border-radius: 50%;
//    cursor: pointer;
//    z-index: 2;
//}
#min_value {
    position: absolute;
    top: -32px;
}
#max_value {
    position: absolute;
    top: -32px;
    right: 0;
}
