#mainmenu{
	transition: all 0.3s cubic-bezier(0.595, 0.060, 0.550, 0.425);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9000;
	border-bottom: 1px solid rgba(255, 255, 255, 0.43);
	.has-submenu {
		@include media-breakpoint-up(lg) {
			padding: 24px 0;
		}

		&:hover {
			.a-sticky-nav {
				&::before{
					width: calc(100% - 12px);
				}
			}
		}
		&.active {
			.a-sticky-nav {
				&::before{
					width: calc(100% - 12px);
				}
			}
		}
		&.aa {
			.a-sticky-nav {
				&::before{
					width: calc(100% - 12px);
				}
			}
		}
	}
	.a-sticky-nav{
		padding: 0 5px;
		@media (min-width: 1400px){
			margin: 0 0 0 15px;
			&.voc_skate {
				margin-left: 30px;
			}
		}
		&.active{
			color: $green-c !important;
		}
		&.aa {
			&::before{
				width: calc(100% - 12px);
			}
		}
	}
	.voc_exp{
		position: relative;
		color: $anthracite-s;
		@media (min-width: 1400px){
			margin-left: 20px !important;
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 10px;
			right: -5px;
			background-color: white;
			transition: background-color 0.35s ease-in-out;
			transform: skewX(-20deg);
			z-index: -1;
		}
		&:before{
			content: none;
		}
		&:hover{
			color: $anthracite-s;
			&:after{
				background-color: rgba(255, 255, 255, 0.8);
			}
		}
	}
	.lingua_usa{
		display: flex;
		img{
			width: 16px;
			height: auto;
		}
	}
	&.scrolled{
		transform: translateY(-100%);
	}
	.box_logo{
		display: block;
		width: 100px;
		@media (min-width: 768px){
			width: 130px;
			margin-right: 45px;
		}

        .recall_usa &{
            margin: 3px 0;
        }
	}
	#logo{
		width: 100%;
		position: relative;
		path{
			fill: $anthracite-s;
			@media (min-width: 768px){
				fill: white;
				fill-opacity: 1;
			}
		}
		@media (min-width: 1024px){
			vertical-align: baseline;
		}
	}
	svg{
		path{
			transition: all 0.35s ease-in-out;
		}
	}
	.icn_cerca{
		path{
			transition: all 0.35s ease-in-out;
			fill: $anthracite-s;
			@media (min-width: 992px){
				fill: white;
				fill-opacity: 1;
			}
		}
		&:hover {
			path{
				fill: $green-c;
			}

		}
	}
	#nav-icon3 span{
		background: $anthracite-s;
		@media (min-width: 768px){
			background-color: white;
		}
	}
	&.fullcolor{
		background-color: $white;
		border-bottom: 1px solid #dedede;
		.a-sticky-nav{
			color: $anthracite-s;
		}
		#logo{
			path{
				fill: $anthracite-s;
			}
		}
		#change_state, #acc_icn, #cart_icn, .icn_cerca, .icn_cus, #cart_icn_usa, #store-locator {
			path{
				fill: #989ea3;
			}
		}
		.carrellousa{
			.mycart_usa{
				span{
					color: $anthracite-s;
				}
			}
		}
		.lingua_usa{
			color: $anthracite-s;
		}
	}
	#mnmob{
		position: absolute;
		top: 75px;
		height: 100vh;
		left: 0;
		right: 0;
		padding: 30px;
		transition: all 0.5s ease-in-out;
		transform: translateX(100%);
        //@media (max-width: 768px){
        //    top: 0;
        //    z-index: 1000;
        //}
		.a-sticky-nav{
			display: block;
			text-align: center;
			color: $anthracite-s;
			line-height: 40px;
			@media (max-width: 768px){
				font-size: 18px;
			}
		}
		&.show{
			transform: translateX(0);

            .recall_usa &{
                top: 95px;
            }
		}
	}
    .btn-close {
        background-color: transparent;
        border-width: 0;
        padding: 0;
    }
	.mn_mob{
		.dealer{
			display: flex;
			align-items: center;
			margin-right: 15px;
		}
		svg{
			path{
				fill: #989ea3;
				fill-opacity: 1;
			}
		}
		.carrellousa .mycart_usa span, .minicart .mycart_usa span{
			color: $anthracite-s;
		}
	}
	&.internati{
		border-bottom: 1px solid #dedede;
		background-color: #fff;
		.a-sticky-nav{
			color: $anthracite-s;
		}
		#logo{
			path{
				fill: $anthracite-s;
			}
		}
		#change_state, #acc_icn, #cart_icn, #cart_icn_usa, .icn_cus, #store-locator{
			path{
				fill: #989ea3;
				fill-opacity: 1;
			}
		}
		.icn_cerca{
			path{
				fill: $anthracite-c;
				fill-opacity: 1;
			}
		}
		#nav-icon3 span{
			background: $anthracite-s;
		}
		.dropdown-account{
			svg{
				path{
					fill: #989ea3;
					fill-opacity: 1;
				}
			}
		}
		.carrellousa .mycart_usa span, .minicart .mycart_usa span{
			color: $anthracite-s;
		}
	}
	.dropdown-account{
		.dropdown-menu{
			background-color: $anthracite-s;
			border: none;
			a{
				font-family: 'ManifoldCF-BoldOblique',sans-serif;
				font-size: 14px;
				color: #fff;
				line-height: 30px;
				&:hover{
					color: $green-c;
					background-color: transparent;
				}
			}
		}
	}
	.mn_ice{
		position: relative;
		display: inline-block;
		@media (max-width: 768px){
			width: 100%;
		}
		.drop_ice{
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			background-color: $anthracite-s;
			border-radius: 0 0 5px 5px;
			padding: 10px;
			z-index: 9999;
			min-width: 150px;
			@media (max-width: 768px){
				width: 100%;
				a{
					display: block;
					text-align: center;
				}
			}
			a{
				font-family: 'ManifoldCF-BoldOblique',sans-serif;
				font-size: 14px;
				color: #fff;
				line-height: 30px;
				&:hover{
					color: $green-c;
				}
			}
		}
		&.open{
			.icebtn{
				margin: 0;
				padding: 3px 10px;
				background-color: $anthracite-s;
				border-radius: 5px 5px 0 0;
				color: #fff !important;
				&:hover{
					&::before{
						content: none;
					}
				}
			}
		}
	}
	#change_state{
		span, path{
			transition: $transition;
		}
		&:hover{
			span{
				color: $green-c;
			}
			svg{
				path{
					fill: $green-s;
				}
			}
		}
	}
	.icn_cerca{
		form{
			display: none;
		}
	}
	#acc_icn, #cart_icn, .icn_cus, #cart_icn_usa, #store-locator{
		&:hover{
			path{
				fill: $green-c;
			}
		}
	}
	&.awayUp {
		transform: translateY(-100%);
	}
    //#submenu-Women .menu-lv-2[data-value-lv2="Ice"] {
    //    color: #11A2A2;
    //}
    .woman-ice {
        color: #11A2A2;
    }
}

#nav-icon3{
	padding: 0;
	width: 20px;
	height: 13px;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	span{
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: white;
		border-radius: 1px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
		&:nth-child(1){
			top: 0;
		}
		&:nth-child(2){
			top: 6px;
		}
		&:nth-child(3){
			top: 6px;
		}
		&:nth-child(4){
			top: 12px;
		}
	}
	&.open span{
		&:nth-child(1){
			top: 8px;
			width: 0;
			left: 50%;
		}
		&:nth-child(2){
			transform: rotate(45deg);
		}
		&:nth-child(3){
			transform: rotate(-45deg);
		}
		&:nth-child(4){
			top: 8px;
			width: 0;
			left: 50%;
		}
	}
}

//cerca
.search-ct {
	&:before {
		background: #000;
		opacity: 0;
		position: fixed;
		z-index: 9085;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		content: '';
		display: none;
		transition: all .5s;
	}
	.search-wrap {
		position: fixed;
		left: 10px;
		right: 10px;
		top: 10px;
		z-index: 9090;
		padding: 60px 30px;
		background: #FFF;
		transition: all .5s;
		transform: scaleY(0);
		transform-origin: center top;
	}
	.search-wrap-2 {
		opacity: 0;
		transition: all .5s;
		transform: translateY(100px);
		transition-delay: .25s;
	}
	&.st1 {
		&:before {
			display: block;
		}
	}
	&.st2 {
		&:before {
			opacity: .25;
		}
	}
	&.on {
		.search-wrap {
			transform: scaleY(1);
		}
		.search-wrap-2 {
			opacity: 1;
			transform: translateY(0px);
		}
	}
	.ui-searchinput {
		width: 100%;
		font-size: 1.84874rem;
		color: #313131;
		padding: 10px 0;
		background: 0 0;
		border:none;
		&::placeholder {
			color: #c8c8c8;
		}
	}
	label {
		font-family: 'ManifoldCF-BoldOblique',sans-serif;
		text-transform: uppercase;
	}
	.icon--close {
		position: absolute;
		right: 15px;
		top: 15px;
		cursor: pointer;
		width: 12px;
		height: 12px;
		div {
			top: 0;
			width: 100%;
			height: 2px;
			left: 0;
			background: #313131;
			position: absolute;
			margin: auto;
			bottom: 0;
			right: 0;
			transform: rotate(45deg);
			&:first-of-type {
				transform: rotate(-45deg);
			}
		}
	}
	.suggestions-column {
		ul {
			li {
				margin-top: 5px;
				&:first-of-type {
					margin-top: 0;
				}
			}
		}
	}
}
.icn_cerca {
	cursor: pointer;
}

/* dealer */
#scegli_paese_start{
	z-index: 9999;
	.modal-content{
		display: block;
		border: none;
		.list_deal{
			transform: scaleY(1);
			.contbox{
				height: 100vh;
				overflow: auto;
				@media (min-width: 768px){
					height: auto;
				}
			}
		}
	}
}

#dealerListH{
	&:before{
		background: #000;
		opacity: 0;
		z-index: 9999;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		content: '';
		display: none;
		transition: all .5s ease-in-out;
	}
	/*.list_deal{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		will-change: transform;
		transition: all .5s ease-in-out;
		transform: scaleY(0);
		transform-origin: center top;
		z-index: 9999;
	}*/
	&.open{
		&:before{
			display: block;
		}
	}
	&.on1{
		&:before{
			opacity: .25;
		}
	}
	&.on2{
		.list_deal{
			transform: scaleY(1);
			height: calc(100vh - 30px);
			overflow-y: auto;
		}
	}
	.title{
		position: relative;
		border-bottom: 1px solid #DDD;
		@media (min-width: 768px){
			padding: 40px;
		}
		strong{
			font-family: 'ManifoldCF-Bold',sans-serif;
		}
		#close{
			position: absolute;
			top: -40px;
			left: 50%;
			margin-left: -15px;
			z-index: 999;
			cursor: pointer;
			width: 30px;
			height: 30px;
			&:before, &:after {
				content: ' ';
				position: absolute;
				height: 30px;
				width: 2px;
				background-color: $anthracite-s;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
			&:hover{
				opacity: 0.5;
			}
		}
	}
	.list{
		.gruppocont{
			font-family: 'ManifoldCF-Bold',sans-serif;
			font-size: 14px;
			text-transform: uppercase;
			margin-left: 23px;
			margin-top: 20px;
			@media (min-width: 768px){
				margin-top: 0;
			}
		}
		ul{
			li{
				.princoun{
					color: $anthracite-s;
					img{
						margin-right: 10px;
						height: 11px;
						margin-top: -2px;
					}
					&:hover{
						opacity: 0.5;
					}
					.nocart{
						width: 22px;
						display: inline-block;
					}
				}
				.altre_lingue{
					margin-left: 5px;
					font-size: 12px;
					a{
						display: inline-block;
						font-family: 'ManifoldCF-Bold',sans-serif;
						font-size: 12px;
						color: $anthracite-s;
						text-transform: uppercase;
						&:hover{
							color: $green-c;
						}
					}
				}
			}
		}
	}
}

//footer
.first_ft{
	@media (min-width: 1600px){
		min-height: 330px;
	}
	a{
		svg{
			path{
				transition: $transition;
			}
		}
		p{
			margin-bottom: 0;
			transition: $transition;
		}
		&:hover{
			svg{
				path{
					fill: $green-c;
					opacity: 1;
				}
			}
			p{
				opacity: 0.8;
			}
		}
	}
}
.second_ft{
	ul{
		li{
			line-height: 25px;
		}
	}
	.img_pay{
		opacity: 0.8;
		filter: grayscale(100%);
		max-width: 275px;
		width: 100%;
	}
	.social_ft{
		a{
			&:hover{
				svg{
					path{
						opacity: 1;
					}
				}
			}
			svg{
				margin-right: 10px;
				path{
					transition: $transition;
				}
				@media (min-width: 992px) {
					margin-right: 30px;
				}
			}
		}
	}
}

//instagram play
.lightwidget__image-wrapper--video:before{
	display: none !important;
}

/* carrello usa*/
.carrellousa, .minicart{
	position: relative;
	.mycart_usa{
		span{
			color: white;
			font-size: 12px;
		}
	}
}
.cart_usa{
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	background-color: $bkg-gray-l;
	padding: 30px 15px;
	transform: scaleY(0);
	transform-origin: center top;
	transition: all .5s;
	min-width: 300px;
	&.open{
		opacity: 1;
		transform: scaleY(1);
	}
	.close_usa{
		position: absolute;
		top: 10px;
		right: 10px;
		line-height: 16px;
		font-size: 18px;
		z-index: 999;
		cursor: pointer;
		transition: all 0.35s ease-in-out;
		&:hover{
			color: $green-c;
		}
	}
	#viewCartUsa{
		display: block;
		text-transform: uppercase;
		background-color: $green-c;
		&:hover{
			background-color: $anthracite-c;
			color: white;
		}
	}
}
.cart{
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	background-color: $bkg-gray-l;
	padding: 30px 15px;
	transform: scaleY(0);
	transform-origin: center top;
	transition: all .5s;
	min-width: 300px;
	&.open{
		opacity: 1;
		transform: scaleY(1);
	}
	.close_cart{
		position: absolute;
		top: 10px;
		right: 10px;
		line-height: 16px;
		font-size: 18px;
		z-index: 999;
		cursor: pointer;
		transition: all 0.35s ease-in-out;
		&:hover{
			color: $green-c;
		}
	}
	#viewCart{
		display: block;
		text-transform: uppercase;
		background-color: $green-c;
		&:hover{
			background-color: $anthracite-c;
			color: white;
		}
	}
}
.offcanvas-nav__btn-label, .offcanvas-subNav__item .offcanvas-subSubNav__btn-label, .offcanvas-subNav__item.h5, .offcanvas-subNav__item a {
    font-size: 18px !important;
}

.offcanvas-nav__btn-label {
    color:black !important;
}

.a-sticky-nav.voc_ss, .offcanvas-nav li:first-child .offcanvas-nav__btn-label {
	position: relative;
	color: $anthracite-s;
	@media (min-width: 1400px){
		margin-left: 20px !important;
	}
	&:after{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: -2px;
		background-color: $green-c;
		transition: background-color 0.35s ease-in-out;
		transform: skewX(-20deg);
		z-index: -1;
	}
	&:before{
		content: none;
	}
	&:hover{
		color: $anthracite-s;
		&:after{
			background-color: $green-s;
		}
	}
}
.offcanvas-nav li:first-child .offcanvas-nav__btn-label {
    padding: .75rem;
}

.rollSubmenu {
	position: absolute;
	top: calc(100% - 1px);
	left: 0;
	width: 100%;
	&__back {
		font-family: $fontf--boldob;
		font-size: 15px;
		line-height: 1;
		margin: 16px 0;
		display: flex;
		align-items: center;
		color: #323e47;
		img {
			height: 19px;
			width: auto;
			margin: 0 4px 0 0;
		}
	}
	&__wrap {
		padding: 0 0 mul(8) 0;
		transition: all .5s;
		opacity: 0;
		text-align: center;
		@include media-breakpoint-up(lg) {
			text-align: left;
			padding: mul(8) 0;
		}
		&.on {
			opacity: 1;
		}
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #FFF;
		border-top: 1px solid #DEDEDE;
		transition: all .5s;
		opacity: 0;
		box-shadow: 0px 10px 10px rgba(0, 0, 0, .25);
		&.on {
			opacity: 1;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	&__ct {
		display: none;
		@include media-breakpoint-down(md) {
			height: calc(100vh - 75px);
			overflow: auto;
			background: #f1f1ee;
		}
		&.on {
			display: block;
		}

	}

	&__lastCol {
	}
	&__list {
		@include media-breakpoint-down(md) {
			margin-bottom: 32px;
		}
		li {
			margin: mul(1) 0 0 0;
			&:first-child {
				margin: 0;
			}
		}
		a {
			color: $blue--1;
			//text-transform: uppercase;
			//go
			&:hover {
				color: $green-c;
			}
		}
		&--1 {
			li {
				margin: mul(2) 0 0 0;
			}
			a {
				//text-transform: uppercase;
				font-family: $fontf--extraboldob;
				font-size: 14px;
				&:hover {
					color: $green-c;
				}
			}
		}
	}
	&__title {
		font-family: $fontf--extraboldob;
		text-transform: uppercase;
		font-size: 15px;
		letter-spacing: 0;
		line-height: 1;
		margin: mul(2) 0 mul(1) 0;
		&--1 {
			@extend %highlight;
			text-transform: uppercase;
			position: relative;
			z-index: 0;
			display: inline-block;
			margin: 0 0 0 8px;
			padding: 4px 8px;
		}
	}
	&__banner {
		margin: mul(5) 0 0 0;
		display: inline-block;
		transition: all .5s;
		&:hover {
			transform: scale(1.05);
		}
	}
}

.menuRoll {
	&--wrap {
		position: relative;
		z-index: 99;
	}
	&--wrapRow {
		@include media-breakpoint-down(md) {
			min-height: 75px;
		}

	}
}


// Site menu mobile 2025

// Offcanvas
$offcanvas-padding-x: 1.5rem;


#offcanvas-mobile-toggler {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.offcanvas-mobile {
    transform: translateX(100%);
    background-clip: padding-box;
    background-color: white;
    bottom: 0;
    color: black;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    visibility: hidden;
    z-index: 1045;
}
.offcanvas.offcanvas-end {
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: 400px;
    pointer-events: none;
}
body.offcanvas-mobile-shown .offcanvas {
    transform: translateX(0);
    visibility: visible;
    pointer-events: all;
    transition: transform 0.3s ease-in-out;
}
.min-height-dvh-100 {
    min-height: 100dvh;
}

.burger-icon {
    border-radius: 0.625rem;
    //@include burger(1.25rem, 2px, 4px, currentcolor);

    &:before {
        width: 1.25rem;
        border-radius: 0.625rem;
    }

    &:after {
        width: 0.75rem;
        border-radius: 0.625rem;
    }
}

.menu-button.is-active .burger-icon {
    //@include burger-to-cross;

    &:before,
    &:after {
        width: 20px;
    }
}

#main-header .navbar-toggler {
    body.offcanvas-mobile-shown & {
        display: none;
    }
}

#offcanvas-mobile {
    .offcanvas-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5rem;
        padding: 1.25rem 1.5rem;
    }

    .offcanvas-body {
        overflow-x: clip;
        padding: 1rem 1.25rem 0;
    }
    .offcanvas-nav__item.has-border-bottom,
    .offcanvas-subNav__item.has-border-bottom {
        &:after {
            content: "";
            display: block;
            height: 1px;
            background-color: $gray-600;
            position: relative;
            z-index: -1;
            width: calc(100% + #{$offcanvas-padding-x} * 2);
            left: -#{$offcanvas-padding-x};
        }
    }

    .offcanvas-nav__item.has-border-top,
    .offcanvas-subNav__item.has-border-top {
        &:before {
            content: "";
            display: block;
            height: 1px;
            background-color: $gray-600;
            position: relative;
            z-index: -1;
            width: calc(100% + #{$offcanvas-padding-x} * 2);
            left: -#{$offcanvas-padding-x};
        }
    }

    .offcanvas-nav__btn,
    .offcanvas-subNav__btn,
    .offcanvas-subSubNav__btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between
    }
    .offcanvas-subSubNav__btn-icon {
        transition: rotate .3s;
    }

    .offcanvas-subSubNav__btn[aria-expanded=true] {
        .offcanvas-subSubNav__btn-icon {
            rotate: 45deg;
        }
    }
    .icon--plus-lg {
        background-image: url(/assets/img/ico-plus-grey.svg);
    }
    .icon {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 1rem;
        transition: filter .3s;
        width: 1rem;
    }
    .offcanvas-subNav__btn-label {
        color:black !important;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .offcanvas-subNav {
        background-color: white;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(100vw);
        transition: transform .4s cubic-bezier(.76, .2, .43, .89);
        padding-left: $offcanvas-padding-x;
        padding-right: $offcanvas-padding-x;

        &.show {
            transform: translateX(0);
        }
    }

    .offcanvas-subNav__btn-icon {
        transform: rotate(180deg);
    }

    .offcanvas-subNav__btn-back {
        font-size: 1rem;
        color: $gray-700;
        font-weight: 600;
        display: inline-block;
        margin-bottom: .1rem;
        margin-left: .5rem;
    }

    .offcanvas-subSubSubNav__item:not(:last-of-type) {
        margin-bottom: .6rem;
    }
}
